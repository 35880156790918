import HtmlBuilder from "./html-builder";
import Payment from "./index";
const { IREMBOPAY_CURRENT_ENV } = require("./config.js");

var WAITING_FOR_PAYMENT = false;
var LAST_CHECK_INVOICE_STATUS = "";
var STATUS_CHECK_WAITING_DURATION = 20 * 1000;
let POPUP_MONITOR_DURATION = 5 * 1000;
var INVOICE_NUMBER = "";
var PAYMENT_REFERENCE = "";
var IREMBOPAY_URLS = {
  prod: "https://api.irembopay.com",
  uat: "https://api.uat.irembopay.com",
  dev: "https://api.dev.irembopay.com",
  devA: "https://api.dev-a.irembopay.com",
  devB: "https://api.dev-b.irembopay.com",
  devC: "https://api.dev-c.irembopay.com",
  sandbox: "https://api.sandbox.irembopay.com",
  local: "http://127.0.0.1:8492",
};

let SHOULD_USE_SHORT_POLLING = false;
let eventSource = null;
let eventSourceReconnectInterval = 1;
let statusCheckInterval = null;
let popupMonitorinterval = null;

export class IremboPayEnv {
  static prod = "prod";
  static uat = "uat";
  static dev = "dev";
  static devA = "devA";
  static devB = "devB";
  static devC = "devC";
  static sandbox = "sandbox";
  static local = "local";
}

export default class Actions {
  static IREMBOPAY_CURRENT_ENV = IremboPayEnv.uat;

  static changePayVia(changeTo, invoiceNumber, currency, amount, isMPGS) {
    var paymentMethods = {
      "irembopay-momo": "MTN Mobile Money",
      "irembopay-momo-airtel": "Airtel Money",
      "irembopay-card": "Debit/Credit Card",
      "irembopay-offline": "Cash/Agents",
      "irembopay-bank": "Bank Accounts",
      "irembopay-bank-transfer": "Bank Transfer",
    }
    Payment.SELECTED_PAYMENT_METHOD = paymentMethods[changeTo];
    Payment.trackEvent("Switch Payment Method",{
      invoiceNumber: Payment.IREMBOPAY_VERIFICATION_DATA.invoiceNumber,
      currency: Payment.IREMBOPAY_VERIFICATION_DATA.currency,
      merchant: Payment.IREMBOPAY_VERIFICATION_DATA?.appAccount?.name,
      paymentMethod: Payment.SELECTED_PAYMENT_METHOD
    })
    window.CARD_SELECTED = false;
    const momoContainer = document.getElementById(
      "irembopay-momoFormContainer"
    );
    const cardSection = document.getElementById("irembopay-paymentCardForm");
    const offlineContainer = document.getElementById(
      "irembopay-offlineFormContainer"
    );
    const bankSection = document.getElementById("irembopay-bank-section");
    const cardSpinner = document.getElementById("irembopay-card-spinner-container");
    if (cardSpinner) cardSpinner.style.display = "none";

    const bankTransferSection = document.getElementById("irembopay-bank-transfer-section");
    const afterBankTransferSection = document.getElementById("irembopay-after-bank-transfer-section");
    const bankTransferUpdateSection = document.getElementById("irembopay-bank-transfer-update-section");

    var elementsToHide = [];
    if (["irembopay-momo", "irembopay-momo-airtel"].includes(changeTo)) {
      elementsToHide = [cardSection, offlineContainer, bankSection, cardSpinner, bankTransferSection, afterBankTransferSection, bankTransferUpdateSection];
      momoContainer.classList.add("irembopay-show");
    } else if (changeTo === "irembopay-card") {
      window.CARD_SELECTED = true;
      if (isMPGS && !window.MPGS_INITIALIZED) {
        elementsToHide = [momoContainer, offlineContainer, bankSection, cardSection, bankTransferSection, afterBankTransferSection, bankTransferUpdateSection];
        cardSpinner.style.display = "flex";
      } else {
        elementsToHide = [momoContainer, offlineContainer, bankSection, cardSpinner, bankTransferSection, afterBankTransferSection, bankTransferUpdateSection];
        cardSection.classList.add("irembopay-show");
      }
    } else if (changeTo === "irembopay-offline") {
      elementsToHide = [momoContainer, cardSection, bankSection, cardSpinner, bankTransferSection, afterBankTransferSection, bankTransferUpdateSection];
      offlineContainer.classList.add("irembopay-show");
    }
    else if (changeTo === "irembopay-bank") {
      elementsToHide = [momoContainer, cardSection, offlineContainer, cardSpinner, bankTransferSection, afterBankTransferSection, bankTransferUpdateSection];
      bankSection.classList.add("irembopay-show");
    }
    else if (changeTo === "irembopay-bank-transfer") {
      elementsToHide = [momoContainer, cardSection, offlineContainer, bankSection, cardSpinner, afterBankTransferSection, bankTransferUpdateSection];
      var alertObj = document.getElementById("irembopay-alertBox")
      var spinner = alertObj.appendChild(document.createElement("div"))
      spinner.classList.add("irembopay-bank-transfer-spinner")
      spinner.innerHTML = HtmlBuilder.getSpinner();
      spinner.style.display = "flex";
      spinner.style.justifyContent = "center";
      spinner.style.alignItems = "center";

      try {
        Payment.getBankTransferDetails(invoiceNumber)
        .then((res) => {
          if (res.success) {
            var bankTransfer = {
              bank: {
                name: res.data.bankName,
                swiftCode: res.data.bankSwiftCode
              },
              accountNumber: this.formatAccountNumberString(res.data.accountNumber),
              accountName: res.data.accountName
            }

            var invoiceDetails = {
              invoiceNumber,
              amount: amount,
              currency
            }
            Payment.getCollectionAccount(currency)
            .then((res) => {
              if (res.success) {
                var collectionAccount = res.data;
                alertObj.removeChild(spinner)
                if (bankTransferUpdateSection !== null) {
                  alertObj.removeChild(bankTransferUpdateSection)
                }
                HtmlBuilder.createBankTransferUpdateSection(bankTransfer, invoiceDetails, collectionAccount);
              }
            })
          }
        })
        .catch((_) => {
          console.log(_)
          alertObj.removeChild(spinner)
          bankTransferSection.classList.add("irembopay-show");
        });
      } catch(_) {
        alertObj.removeChild(spinner)
        bankTransferSection.classList.add("irembopay-show");
      }
    }

    this.hideElements(elementsToHide);
    document.getElementById("irembopay-phone").value = "";
    Actions.setErrorText("");

    Actions.setRadioButtonValue("irembopay-payment_option", changeTo);
    Actions.setCheckBoxValue("irembopay-payment-options", changeTo);
  }

  static formatAccountNumberString(str) {
    return str.replace(/(\d{4})(?=\d)/g, "$1-");
  }

  static setRadioButtonValue(radioName, selectedValue) {
    var radioElements = document.getElementsByName(radioName);
    for (var node of radioElements) {
      var radioLabel = document.getElementById(`${node.value}_radio`);
      if (node.value == selectedValue) {
        node.checked = true;
        radioLabel.classList.add("irembopay-text-teal-deep");
      } else {
        node.checked = false;
        radioLabel.classList.remove("irembopay-text-teal-deep");
      }
    }
  }

  static setCheckBoxValue(checkBoxId, selectedValue) {
    document.getElementById(checkBoxId).value = selectedValue;
  }

  static hideElements(elementsToHide) {
    try {
      elementsToHide.map((item) => {
        if(item != null){
          item.classList.remove("irembopay-show");
          item.classList.add("irembopay-hide");
        }
      });
    } catch (_) {}

  }

  static onPaymentSuccess(callback, btn) {
    btn.classList.add("irembopay-button--loading");
    setTimeout(function () {
      btn.classList.remove("irembopay-button--loading");
      HtmlBuilder.showSuccessBox();
      if (callback && typeof callback === "function") {
        callback({
          success: true,
          message: "Payment Successful",
          data: [],
        });
      }
    }, 5000);
  }

  static removeCustomAlert() {
    try {
      const closeEvent = new CustomEvent('irembopay-modal-closed', {
        detail: {
          invoiceNumber: Payment.IREMBOPAY_PAYMENT_DATA.invoiceNumber,
          paymentStatus: LAST_CHECK_INVOICE_STATUS
        }
      });
      document.dispatchEvent(closeEvent);

      document
        .getElementsByTagName("body")[0]
        .removeChild(
          document.getElementById("irembopay-iremboPayModalContainer")
        );
      Actions.closeEventSource();
    } catch (error) {
      Payment.trackEvent('Payment Failure', {
        invoiceNumber: Payment.IREMBOPAY_PAYMENT_DATA.invoiceNumber,
        currency: Payment.IREMBOPAY_PAYMENT_DATA.currency,
        merchant: Payment.IREMBOPAY_PAYMENT_DATA?.appAccount?.name,
        paymentMethod: Payment.SELECTED_PAYMENT_METHOD,
        errorMessage: error
      });
    }
  }

  static initiateTransactionStatusCheck(paymentData, metaData) {
    WAITING_FOR_PAYMENT = true;
    INVOICE_NUMBER = Payment.IREMBOPAY_VERIFICATION_DATA.invoiceNumber;
    PAYMENT_REFERENCE = paymentData?.paymentReference;

    if (metaData.isCard) {
      STATUS_CHECK_WAITING_DURATION = 40 * 1000;
    }

    this.iniateStatusCheckTimeout();

    statusCheckInterval = setInterval(() => {
      if (!WAITING_FOR_PAYMENT) {
        clearInterval(statusCheckInterval);
        clearInterval(popupMonitorinterval);
        if (metaData.isCard) {
          HtmlBuilder.showCardErrors({ show: true });
          Actions.toggleCardDisplay({ disable: false });
          Payment.SetRetryCard(true);
        } else {
          HtmlBuilder.togglePaymentNotReceived({ show: true });
          Actions.setButtonText(metaData.buttonId, metaData.buttonText);
        }
      } else {
        if (SHOULD_USE_SHORT_POLLING) {
          Payment.getTransactionStatus(paymentData)
            .then((res) => {
              if (res['paymentStatus'].toUpperCase() === 'PAID') {
                clearInterval(statusCheckInterval);
                clearInterval(popupMonitorinterval);
                HtmlBuilder.showSuccessBox();
                paymentData.callback(null, { message: "Payment successful" });
                Actions.closeEventSource();
              }
            })
            .catch((_) => {});
        }
      }
    }, 5000);

    if (metaData && metaData.isCard && metaData.popup && metaData.popup.closed) {
      clearInterval(statusCheckInterval);
      POPUP_MONITOR_DURATION = 1 * 1000;
    }

    popupMonitorinterval = setInterval(() => {
      if (metaData && metaData.isCard && metaData.popup && metaData.popup.closed) {
        Actions.toggleCardDisplay({ disable: true });
        if (SHOULD_USE_SHORT_POLLING) {
          Payment.getTransactionStatus(paymentData)
            .then((res) => {
              if (res['paymentStatus'].toUpperCase() === 'PAID') {
                clearInterval(statusCheckInterval);
                clearInterval(popupMonitorinterval);
                HtmlBuilder.showSuccessBox();
                paymentData.callback(null, { message: "Payment successful" });
                Actions.closeEventSource();
              } else {
                Actions.toggleCardDisplay({ disable: false });
              }
            })
            .catch((_) => {
              Actions.toggleCardDisplay({ disable: false });
            });
        }
      }
    }, POPUP_MONITOR_DURATION);
  }

  static iniateStatusCheckTimeout() {
    setTimeout(() => {
      WAITING_FOR_PAYMENT = false;
    }, STATUS_CHECK_WAITING_DURATION);
  }

  static toggleButtonClicked(id) {
    var button = document.getElementById(id);
    var buttonClickedClass = "irembopay-button-clicked";
    if (button.classList.contains(buttonClickedClass)) {
      button.classList.remove(buttonClickedClass);
    } else {
      button.classList.add(buttonClickedClass);
    }
  }

  static getButtonText(id) {
    return document.getElementById(id).innerText;
  }

  static setButtonText(id, value) {
    try {
      document.getElementById(id).innerHTML = value;
    } catch (_) {}
  }

  static setButtonLoading(id, isLoading) {
    try {
      var loadingClassName = "irembopay-button--loading";
      var targetButton = document.getElementById(id);
      if (isLoading == true) {
        targetButton.classList.add(loadingClassName);
      } else {
        targetButton.classList.remove(loadingClassName);
      }
    } catch (_) {}
  }

  static validPhoneNumber(value) {
    var invalid =
      (value.length >= 1 && value.substring(0, 1) != "0") ||
      (value.length >= 2 && value.substring(0, 2) != "07");
    if (!invalid) {
      return value.length === 10;
    }
    return false;
  }

  static toggleMomoInputField({ disable }) {
    var inputField = document.getElementById("irembopay-phone");
    var button = document.getElementById("irembopay-payBtn");
    if (disable) {
      inputField.disabled = true;
      button.disabled = true;
    } else {
      inputField.disabled = false;
      button.disabled = false;
    }
  }

  static toggleCardDisplay({ disable }) {
    var cardForm = document.getElementById("irembopay-cardForm");
    for (var element of cardForm.elements) {
      element.disabled = disable;
    }

    Actions.disableButtonToggle("irembopay-cardPayBtn", disable);

    var btn = document.getElementById("irembopay-cardPayBtn");
    if (disable == true) {
      btn.classList.add("irembopay-button--loading");
    } else {
      btn.classList.remove("irembopay-button--loading");
    }
  }

  static refreshInoviceStatus(invoiceNumber) {
    return new Promise((resolve, reject) => {
      var paymentData = {
        invoiceNumber: invoiceNumber,
        paymentReference: PAYMENT_REFERENCE,
      };

      var refreshButton = document.getElementById("irembopay-momoBtnRefresh");
      refreshButton.classList.add(
        "irembopay-button--loading",
        "irembopay-button-clicked"
      );
      refreshButton.disabled = true;

      Payment.getTransactionStatus(paymentData)
        .then((res) => {
          if (res['paymentStatus'].toUpperCase() === 'PAID') {
            HtmlBuilder.showSuccessBox();
            paymentData.callback(null, { message: "Payment successful" });
            Actions.closeEventSource();
          }
          refreshButton.classList.remove(
            "irembopay-button--loading",
            "irembopay-button-clicked"
          );
          refreshButton.disabled = false;
          return resolve();
        })
        .catch((_) => {
          return reject();
        });
    });
  }

  static getSelectedRadioButton(paymentOption) {
    for (var option of paymentOption) {
      if (option.checked) {
        return option.value;
      }
    }
    return "";
  }

  static onCardSubmit(paymentData, verificationData, retry = false) {
    Payment.trackEvent('Initiate Invoice Payment', {
      invoiceNumber: Payment.IREMBOPAY_VERIFICATION_DATA.invoiceNumber,
      currency: Payment.IREMBOPAY_VERIFICATION_DATA.currency,
      merchant: Payment.IREMBOPAY_VERIFICATION_DATA?.appAccount?.name,
      paymentMethod: "CARD CYBERSOURCE"
    });
    var popUpName = "Popup";
    if (retry) {
      popUpName = popUpName + 2;
      document.getElementById("transaction_uuid").value =
        verificationData.transactionUUID;
      document.getElementById("signed_date_time").value =
        verificationData.signedDateTime;
      document.getElementById("signature").value = verificationData.signature;
    }

    var form = document.getElementById("irembopay-cardForm").innerHTML;
    var number = document
      .getElementById("irembopay-cardNumber")
      .value.replace(/\s+/g, "")
      .trim();
    var expiryDate = Actions.formatExpiryDate(
      document
        .getElementById("irembopay-cardDate")
        .value.replace(/\s+/g, "")
        .replace("/", "-")
        .trim()
    );
    var name = document.getElementById("irembopay-cardNameInput").value.trim();
    var cvv = document.getElementById("irembopay-cardCvv").value.trim();
    var popupLocation = this.getPopupUrl();
    var popup = window.open(popupLocation, popUpName, "width=800,height=500");

    setTimeout(() => {
      popup.postMessage(
        {
          form,
          formAction: verificationData.formAction,
          details: {
            name,
            number,
            expiryDate,
            cvv,
          },
        },
        popupLocation
      );
      Actions.toggleCardDisplay({ disable: true });
    }, 5000);

    Actions.initiateTransactionStatusCheck(paymentData, {
      isCard: true,
      popup,
    });
  }

  static formatExpiryDate(date) {
    var position = 3;
    if (date.length === 6) {
      date = [date.slice(0, position), 2, date.slice(position)].join("");
    } else if (date.length === 5) {
      date = [date.slice(0, position), 20, date.slice(position)].join("");
    }
    return date;
  }

  static currencyIs(currency, givenCurrency) {
    return currency.toLowerCase().includes(givenCurrency);
  }

  static setErrorText(message, color) {
    const element = document.getElementById("irembopay-errorText");
    element.innerHTML = message;
    if (color !== null && color !== undefined) {
      element.style.color = color;
    }
  }

  static validatePhoneNumber(target, paymentMethod, context) {
    var value = target.value;
    if (Actions.isNotNumber(value)) {
      Actions.invalidPhoneAction(target,context);
      Actions.disableButtonToggle("irembopay-payBtn", true);
    } else {
      Actions.setErrorText("");
      if (
        !Actions.validPhoneNumber(value) ||
        !Actions.isValidPhoneForProvider(value, paymentMethod)
      ) {
        Actions.invalidPhoneAction(target,context);
        Actions.disableButtonToggle("irembopay-payBtn", true);
      } else {
        Actions.setErrorText("");
        Actions.disableButtonToggle("irembopay-payBtn", false);
      }
    }
  }

  static isNotNumber(val) {
    var isNumber = /^\d*$/.test(val);
    return !isNumber;
  }

  static invalidPhoneAction(target, context) {
    // var value = target.value;
    Actions.setErrorText(context['invalidPhoneNumber']);
    // var newVal = value.toString().substring(0, value.length-1)
    // document.getElementById(target.id).value = newVal
  }

  static clearPhoneNumberError() {
    Actions.setErrorText("");
  }

  static isValidPhoneForProvider(value, provider) {
    var mtnPrefix = ["078", "079"];
    var airtelPrefix = ["073", "072"];
    var prefix = value.substring(0, 3);
    return (
      value.length < 3 ||
      (provider == "irembopay-momo" && mtnPrefix.includes(prefix)) ||
      (provider == "irembopay-momo-airtel" && airtelPrefix.includes(prefix))
    );
  }

  static disableButtonToggle(id, disable) {
    var button = document.getElementById(id);
    if (disable === true) {
      button.disabled = true;
      button.classList.add("irembopay-button-clicked");
    } else {
      button.disabled = false;
      button.classList.remove("irembopay-button-clicked");
    }
  }

  static setEnvironment() {
    try {
      const currentEnv = window.IREMBOPAY_CURRENT_ENV;
      if (currentEnv === "DEV") {
        this.IREMBOPAY_CURRENT_ENV = IremboPayEnv.dev;
      } else if (currentEnv == "DEV-A") {
        this.IREMBOPAY_CURRENT_ENV = IremboPayEnv.devA;
      } else if (currentEnv == "DEV-B") {
        this.IREMBOPAY_CURRENT_ENV = IremboPayEnv.devB;
      } else if (currentEnv == "DEV-C") {
        this.IREMBOPAY_CURRENT_ENV = IremboPayEnv.devC;
      } else if (currentEnv == "SANDBOX") {
        this.IREMBOPAY_CURRENT_ENV = IremboPayEnv.sandbox;
      } else if (currentEnv === "UAT") {
        this.IREMBOPAY_CURRENT_ENV = IremboPayEnv.uat;
      } else if (currentEnv === "LOCAL") {
        this.IREMBOPAY_CURRENT_ENV = IremboPayEnv.local;
      } else {
        this.IREMBOPAY_CURRENT_ENV = IremboPayEnv.prod;
      }
    } catch (_) {
      this.IREMBOPAY_CURRENT_ENV = IremboPayEnv.prod;
    }
  }

  static getApiBaseUrl() {
    try {
      return IREMBOPAY_URLS[this.IREMBOPAY_CURRENT_ENV];
    } catch (_) {
      return IREMBOPAY_URLS[IremboPayEnv.prod];
    }
  }

  static getHostingUrl() {
    var urlMap = {
      prod: "https://dashboard.irembopay.com",
      sandbox: "https://dashboard.sandbox.irembopay.com",
      uat: "https://customer.uat.irembopay.com",
      dev: "https://customer.dev.irembopay.com",
      devA: "https://customer.dev-a.irembopay.com",
      devB: "https://customer.dev-b.irembopay.com",
      devC: "https://customer.dev-c.irembopay.com",
      local: "http://127.0.0.1:7300"
    }

    try {
      return urlMap[this.IREMBOPAY_CURRENT_ENV];
    } catch (e) {
      return urlMap[IremboPayEnv.prod];
    }
  }

  static getPopupUrl() {
    return this.getHostingUrl() + "/assets/payment/src/pop-up.html";
  }

  static getStylesUrl() {
    var url = "style.css";
    return this.getHostingUrl() + "/assets/payment/" + url;
  }

  static registerEvent() {
    var parentNode = document.getElementById(
      "irembopay-iremboPayModalContainer"
    );
    parentNode.addEventListener("click", (e) => {
      if (e.target.matches("input[name='irembopay-payment_option']")) {
        var documentToUpdate = document.getElementById("irembopay-momoInfo");
        documentToUpdate.innerHTML = HtmlBuilder.getMomoInputLabelText(
          e.target.value
        );
      }
    });
  }

  static executeAndCatchError(func) {
    try {
      if (typeof func === "function") {
        func();
      }
    } catch (_) {}
  }

  static initializeStyles() {
    var head = document.getElementsByTagName("HEAD")[0];

    var link = document.createElement("link");
    link.rel = "stylesheet";

    link.type = "text/css";

    link.href = Actions.getStylesUrl();
    head.appendChild(link);
  }

  static initializeFontAwesome() {
    var head = document.getElementsByTagName("HEAD")[0];
    var link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
    head.appendChild(link);
  }

  static getErrorMessage(error, context) {
    try {
      const errors = JSON.parse(error)["errors"];
      const code = errors[0]["code"];
      const detail = errors[0]["detail"];
      if (!code.toString().toUpperCase().includes("SERVER")) {
        return detail;
      }
    }
    catch (_) { }
    return context['defaultErrorMessage'];
  }

  static reconnectEventSource(data) {
    const eventSourceClosed =
      eventSource !== null && eventSource.readyState === 2;
    if (eventSourceClosed) {
      eventSourceReconnectInterval *= 2;
      if (eventSourceReconnectInterval >= 32) {
        eventSourceReconnectInterval = 1;
        SHOULD_USE_SHORT_POLLING = true;
      }

      Actions.registerStatusNotification(data);
    }
  }

  static registerStatusNotification(data) {
    try {
      let url = `${Actions.getApiBaseUrl()}/invoices/${data.invoiceNumber}/notification`;
      if (data.sessionId) {
        url = `${url}?sessionId=${data.sessionId}`;
      }
      eventSource = new EventSource(url);

      eventSource.addEventListener(data.eventKey, (event) => {
        const eventData = JSON.parse(event.data);
        LAST_CHECK_INVOICE_STATUS = eventData['paymentStatus']

        if (eventData['paymentStatus'].toUpperCase() === 'PAID') {
          HtmlBuilder.showSuccessBox();
          Actions.closeEventSource();
          data.callback(null, { message: "Payment successful" });
          clearInterval(statusCheckInterval);
        } else if(eventData['message'] !== undefined && eventData['paymentStatus'].toUpperCase() === 'NEW') {
          var cardError = document.getElementById("irembopay-cardError");
          cardError.innerHTML = eventData['message'];
          HtmlBuilder.showCardErrors({ show: true });
          Actions.toggleCardDisplay({ disable: false });
          Payment.SetRetryCard(true);
        }
      });

      eventSource.onopen = (_) => {
        eventSourceReconnectInterval = 1;
      };

      eventSource.onerror = (_) => {
        Actions.closeEventSource();

        setTimeout(() => {
          Actions.reconnectEventSource(data);
        }, eventSourceReconnectInterval * 1000);
      };
    } catch (err) {
      SHOULD_USE_SHORT_POLLING = true;
    }
  }

  static closeEventSource() {
    try {
      if (eventSource !== null) {
        eventSource.close();
      }
    } catch (_) {}

    Actions.executeAndCatchError(() => {
      clearInterval(statusCheckInterval);
    });
    Actions.executeAndCatchError(() => {
      clearInterval(popupMonitorinterval);
    });
  }
}
