import Payment from ".";
import Actions from "./actions";
import creditCardType from './creditCardType';
import { data } from "jquery";
import PaymentVal from "payment";

var context = {};

var paymentData

var SHOULD_SHOW_PULL_SECTION = true;

var BODY = document.getElementsByTagName("body")[0];

var PAYMENT_METHOD = "irembopay-momo";
var APPROVE_PAYMENT_USSD_CODE_MTN = "*182*7*1#";
var APPROVE_PAYMENT_USSD_CODE_AIRTEL = "*182*5*8#";

var SHOW_BANK_OPTION = true;
var SHOW_OFFLINE_OPTION = true;
var SHOW_ARITEL_OPTION = true;
var SHOW_BANK_TRANSFER_OPTION = false;
var banks = [];

var SHOULD_UPDATE_TRANSFER = false;
export default class HtmlBuilder {
  static toggleLoadingSection({ show }) {
    var alertObj = document.getElementById("irembopay-alertBox");
    alertObj.style.display = "none";

    var loadingSection = document.getElementById("irembopay-loadingSection");

    if (!loadingSection) {
      loadingSection = BODY.appendChild(document.createElement("div"));
      loadingSection.id = "irembopay-loadingSection";

      loadingSection.innerHTML = `
				<h2 style="padding:20px">Waiting for payment ...</h2>
			`;
    }

    var display = show ? "block" : "none";
    loadingSection.style.display = display;
  }

  static togglePaymentNotReceived({ show }) {
    var momoInfoLabel = document.getElementById("irembopay-momoInfoLabel");
    var momoInfoLabel_2 = document.getElementById("irembopay-momoInfoLabel-2");
    var refreshSection = document.getElementById(
      "irembopay-momoRefreshSection"
    );

    if (show) {
      Payment.trackEvent("Payment Not Received", {
        "invoiceNumber": Payment.IREMBOPAY_VERIFICATION_DATA.invoiceNumber,
        "currency": Payment.IREMBOPAY_VERIFICATION_DATA.currency,
        "merchant": Payment.IREMBOPAY_VERIFICATION_DATA?.appAccount?.name,
        "paymentMethod": Payment.SELECTED_PAYMENT_METHOD,
        "errorMessage": "Payment not received"
      })
      Actions.setButtonLoading("irembopay-payBtn", false);
      momoInfoLabel.innerHTML =
        context["paymentNotReceived"].split(".")[0] + ".";
      var infoIconEl = document.getElementById("irembopay-momoInfoIcon");
      infoIconEl.style = "display: inline-flex";
      infoIconEl.innerHTML = this.infoIcon();
      var paymentOptionElement = document.getElementsByName(
        "irembopay-payment_option"
      );
      var payOption = Actions.getSelectedRadioButton(paymentOptionElement);
      var displayMessage = context["paymentNotReceived"].split(".")[1] + ".";

      if (payOption === "irembopay-momo-airtel") {
        displayMessage = displayMessage.replace(
          APPROVE_PAYMENT_USSD_CODE_MTN,
          APPROVE_PAYMENT_USSD_CODE_AIRTEL
        );
      }

      momoInfoLabel_2.innerHTML = displayMessage;
      momoInfoLabel.classList.add("irembopay-text-bold", "irembopay-text-teal");
      momoInfoLabel_2.classList.add("irembopay-text-teal");
      refreshSection.style.display = "flex";
    } else {
      momoInfoLabel.innerHTML = context["payGuide"];
      momoInfoLabel.classList.remove(
        "irembopay-text-bold",
        "irembopay-text-teal"
      );
      momoInfoLabel_2.innerHTML = "";
      refreshSection.style.display = "none";
    }
  }

  static showSuccessBox() {
    Payment.trackEvent("Payment Success", {
      invoiceNumber: Payment.IREMBOPAY_VERIFICATION_DATA.invoiceNumber,
      currency: Payment.IREMBOPAY_VERIFICATION_DATA.currency,
      merchant: Payment.IREMBOPAY_VERIFICATION_DATA?.appAccount?.name,
      paymentMethod: Payment.SELECTED_PAYMENT_METHOD
    })
    var modalContainer = document.getElementById(
      "irembopay-iremboPayModalContainer"
    );
    var alertObj = document.getElementById("irembopay-alertBox");
    alertObj.style.display = "none";
    alertObj.style.visiblity = "hidden";
    modalContainer.removeChild(alertObj);

    var successObj = document.getElementById("irembopay-successBox");
    successObj.style.display = "block";
  }

  static registerMPGSevents() {
    window.addEventListener('message', this.mpgsEvents, false);
  }

  static mpgsEvents(event) {
    if (event.data === "closeAuthDiv") {
      document.getElementById("authenticationParentDiv").style.display = 'none';
    }
    if (event.data === "successfulMPGS") {
      Actions.closeEventSource();
      HtmlBuilder.showSuccessBox();
    }
    if (event.data === "failureMPGS") {
      //re-initiate payment
      Payment.reVerifyInvoice(true, true);
    }
  };

  static removeMPGSEventListeners() {
    window.removeEventListener('message', this.mpgsEvents, false);
  }

  static showVerificationBox() {
    var verificationBox = document.getElementById("irembopay-verification-modal")
    verificationBox.style.display = "block";
    verificationBox.style.visiblity = "visible";
  }

  static createPaymentModal(data, reload = false) {
    HtmlBuilder.registerMPGSevents();
    const { cardPaymentProfile } = data;

    if (cardPaymentProfile === "MPGS") {
      data["invoiceNumber"] = data.mpgsProfileDto.invoiceNumber;
      data["amount"] = data.mpgsProfileDto.amount;
      data["currency"] = data.mpgsProfileDto.currency;
    }

    var modalContainerId = "irembopay-iremboPayModalContainer";
    var doc = document;
    context = data.context;
    paymentData = data;

    var modalObject = doc.getElementById(modalContainerId);

    if (!modalObject) {
      modalObject = doc
        .getElementsByTagName("body")[0]
        .appendChild(doc.createElement("div"));
      modalObject.id = modalContainerId;
    }

    if (data["loading"] === true) {
      this.showNotificationPopUp(modalObject, "Loading payment options", {
        showClosedButton: false,
        showSpinner: true,
      });
      return;
    }

    if (data.error) {
      this.showNotificationPopUp(modalObject, data.error.message, {
        showClosedButton: true,
      });
      return;
    }

    this.createSuccessModal(modalObject, data)

    !reload && this.removeNotificationPopUp(modalObject);

    var alertObj = modalObject.appendChild(doc.createElement("div"));
    alertObj.id = "irembopay-alertBox";
    alertObj.classList.add("irembopay-alertBox");

    alertObj.style.visiblity = "visible";
    alertObj.style.display = "block";

    var paymentTopSelectSection = alertObj.appendChild(
      doc.createElement("div")
    );
    paymentTopSelectSection.classList.add("irembopay-paymentTopSelectSection");

    var payAskLabel = paymentTopSelectSection.appendChild(
      doc.createElement("div")
    );
    payAskLabel.classList.add("irembopay-payAskLabel", "irembopay-text-bold");
    payAskLabel.id = "irembopay-payAskLabel";
    payAskLabel.appendChild(doc.createTextNode(context["paymentOptionsTitle"]));

    var selectBox = paymentTopSelectSection.appendChild(
      doc.createElement("div")
    );
    selectBox.id = "irembopay-selectPayAskLabel";
    selectBox.innerHTML = this.getPaymentOptionsMobile(data.currency, data.appAccount.allowedPaymentProviders);

    var selectRadioButton = paymentTopSelectSection.appendChild(
      doc.createElement("div")
    );

    selectRadioButton.id = "irembopay-selectRadioButton"
    selectRadioButton.innerHTML = this.getPaymentOptionsDesktop(data.currency, data.appAccount.allowedPaymentProviders);

    var momoContainer = alertObj.appendChild(doc.createElement("div"));
    momoContainer.id = "irembopay-momoFormContainer";
    momoContainer.classList.add("irembopay-momoContainer", "irembopay-hide");

    var modalCloseButton = alertObj.appendChild(doc.createElement("div"));
    modalCloseButton.id = "irembopay-modalCloseButton";
    modalCloseButton.innerHTML = "X";
    modalCloseButton.onclick = Actions.removeCustomAlert;

    HtmlBuilder.createMomoPullSection(momoContainer, doc, data);

    var infoParagraph = momoContainer.appendChild(doc.createElement("p"));
    infoParagraph.id = "irembopay-momoInfo";
    infoParagraph.classList.add(
      "irembopay-padding",
      "irembopay-margin",
      "irembopay-text-bold"
    );
    infoParagraph.innerHTML =
      HtmlBuilder.getMomoInputLabelText("irembopay-momo");

    this.momoInputSection(momoContainer, data);

    var errorText = momoContainer.appendChild(doc.createElement("small"));

    errorText.id = "irembopay-errorText";
    errorText.innerHTML = "";
    errorText.classList.add("irembopay-padding");

    var momoBottomSection = document.createElement("div");
    momoBottomSection.id = "irembopay-momoBottomSection";

    momoContainer.appendChild(momoBottomSection);

    var momoInfo = momoBottomSection.appendChild(doc.createElement("div"));
    momoInfo.id = "irembopay-momoInfo";

    var infoIcon = momoInfo.appendChild(doc.createElement("span"));
    infoIcon.id = "irembopay-momoInfoIcon";
    infoIcon.style = "display: none";

    var infoParagraph = momoInfo.appendChild(doc.createElement("p"));
    infoParagraph.id = "irembopay-momoInfoLabel";

    var infoParagraph2 = momoBottomSection.appendChild(doc.createElement("p"));
    infoParagraph2.id = "irembopay-momoInfoLabel-2";

    infoParagraph.appendChild(doc.createTextNode(context["payGuide"]));

    selectBox.onchange = function changePaymentOptions() {
      var value = document.getElementById("irembopay-payment-options").value;
      HtmlBuilder.setValueFromPaymentOption(value, cardPaymentProfile === "MPGS");
    };

    selectRadioButton.onchange = function changePaymentOptions() {
      HtmlBuilder.togglePaymentNotReceived({ show: false });
      var icon = (document.getElementById("irembopay-momoInfoIcon").style =
        "display: none;");

      var paymentOption = document.getElementsByName(
        "irembopay-payment_option"
      );
      var value = Actions.getSelectedRadioButton(paymentOption);
      HtmlBuilder.setValueFromPaymentOption(value, cardPaymentProfile === "MPGS");
    };

    HtmlBuilder.createMomoRefreshSection(data.invoiceNumber);
    //Card payment section
    if (cardPaymentProfile === "MPGS") {
      this.buildMPGSCardSection(alertObj, doc, context, data)
      if (!window.MPGS_INITIALIZED) {
        this.createCardLoadingSection()
      }
    } else {
      this.buildCyberSourceCardSection(alertObj, doc, context, data)
    }

    this.buildBankTransferSection(modalObject, alertObj, doc, context, data)

    this.buildOfflineSection(alertObj, doc, context, data)

    this.buildBankAccountSection(alertObj, doc, context, data)

    this.buildOfflineSection(alertObj, doc, context, data);


    this.showInitialPaymentOption(data.currency, data.appAccount.allowedPaymentProviders, cardPaymentProfile === "MPGS");

    var bottomMarginEl = alertObj.appendChild(doc.createElement("div"));
    bottomMarginEl.classList.add("bottom-margin-el");

    var bottomLogoSection = alertObj.appendChild(doc.createElement("img"));
    bottomLogoSection.id = "irembopay-bottomSectionLogo";
    bottomLogoSection.src =
      Actions.getHostingUrl() + "/assets/payment/img/irembopay_logo.svg";


    var pciLogo = momoContainer.appendChild(doc.createElement("img"));
    pciLogo.classList.add("irembopay-pciImg");
    pciLogo.src = Actions.getHostingUrl() + "/assets/payment/img/pci.svg";
    pciLogo.alt = "Pci";
  }

  static buildCyberSourceCardSection(alertObj, doc, context, data) {
    var cardSection = alertObj.appendChild(doc.createElement("div"));
    cardSection.id = "irembopay-paymentCardForm";
    cardSection.classList.add(
      "irembopay-cardSectionContainer",
      "irembopay-hide"
    );
    //form
    var cardForm = cardSection.appendChild(doc.createElement("form"));
    cardForm.id = "irembopay-cardForm";
    cardForm.action = data.formAction;
    cardForm.method = "POST";
    cardForm.classList.add(
      "irembopay-cardForm",
      "irembopay-margin",
      "irembopay-padding"
    );
    cardForm.autocomplete = "on";
    // cardForm.onsubmit = Actions.onSubmit(event);

    cardForm.innerHTML = `
  <input type="hidden" id="access_key" name="access_key" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="profile_id" name="profile_id" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="transaction_uuid" name="transaction_uuid" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="signed_field_names" name="signed_field_names" value="access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_address_line1,bill_to_address_city,bill_to_address_country,bill_to_address_state,bill_to_address_postal_code" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="unsigned_field_names" name="unsigned_field_names" value="card_type,card_number,card_expiry_date" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="signed_date_time" name="signed_date_time" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="locale" name="locale"  value="en" style="visibility:hidden;line-height:0;">

  <input type="hidden" id="transaction_type" name="transaction_type" value="sale" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="reference_number" name="reference_number" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="amount" name="amount" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="currency" name="currency" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="payment_method" name="payment_method" value="card" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="bill_to_forename" name="bill_to_forename" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="bill_to_surname" name="bill_to_surname" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="bill_to_email" name="bill_to_email" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="bill_to_address_line1" name="bill_to_address_line1" value="1295 Charleston Road" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="bill_to_address_city" name="bill_to_address_city" value="Mountain View" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="bill_to_address_country" name="bill_to_address_country" value="US" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="bill_to_phone" name="bill_to_phone" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="bill_to_address_state" name="bill_to_address_state" value="CA" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="bill_to_address_postal_code" name="bill_to_address_postal_code" value="94043" style="visibility:hidden;line-height:0;">
  <input type="hidden" id="signature" name="signature" style="visibility:hidden;line-height:0;"/>
  <input type="hidden" id="card_type" name="card_type" style="visibility:hidden;line-height:0;">
  `;

    document.getElementById("access_key").value = data.accessKey;
    document.getElementById("profile_id").value = data.profileId;
    document.getElementById("transaction_uuid").value = data.transactionUUID;
    document.getElementById("reference_number").value = data.invoiceNumber;
    document.getElementById("amount").value = data.amount;
    document.getElementById("currency").value = data.currency;
    document.getElementById("bill_to_forename").value =
      data?.customer?.fullName || "Customer";
    document.getElementById("bill_to_surname").value =
      data?.customer?.fullName || "Customer";
    document.getElementById("bill_to_email").value =
      data?.customer?.email || "null@cybersource.com";
    document.getElementById("bill_to_phone").value =
      data?.customer?.phoneNumber || "+250000000000";
    document.getElementById("signed_date_time").value = data.signedDateTime;
    document.getElementById("signature").value = data.signature;

    var cardShortDescr = cardForm.appendChild(doc.createElement("div"));
    cardShortDescr.classList.add("irembopay-text-bold", "irembopay-margin");
    cardShortDescr.id = "irembopay-cardShortDescr";
    cardShortDescr.innerHTML = context["cardDetailsTitle"];
    cardShortDescr.style = "margin-top:32px";

    var cardNameLabel = cardForm.appendChild(doc.createElement("label"));
    cardNameLabel.for = "irembopay-cardName";
    cardNameLabel.classList.add("irembopay-phoneLabel", "irembopay-label");
    cardNameLabel.appendChild(doc.createTextNode(context["cardHoldersName"]));

    var cardNameDiv = cardForm.appendChild(doc.createElement("div"));
    cardNameDiv.classList.add("irembopay-cardNameDiv");
    var cardNameInput = cardNameDiv.appendChild(doc.createElement("input"));
    cardNameInput.type = "text";
    cardNameInput.inputMode = "text";
    cardNameInput.id = "irembopay-cardNameInput";
    cardNameInput.name = "card_name";
    cardNameInput.placeholder = context["nameOnCardPlaceHolder"];
    cardNameInput.classList.add("irembopay-cardInput", "irembopay-input");

    var cardNumberLabel = cardForm.appendChild(doc.createElement("label"));
    cardNumberLabel.for = "irembopay-cardNumber";
    cardNumberLabel.classList.add("irembopay-phoneLabel", "irembopay-label");
    cardNumberLabel.appendChild(doc.createTextNode(context["cardDetails"]));
    var cardNumberDetails = cardForm.appendChild(doc.createElement("div"));
    cardNumberDetails.classList.add("irembopay-cardNumberDetails");
    var cardNumberInput = cardNumberDetails.appendChild(
      doc.createElement("input")
    );
    cardNumberInput.type = "text";
    cardNumberInput.id = "irembopay-cardNumber";
    cardNumberInput.name = "card_number";
    cardNumberInput.pattern = "d*";
    cardNumberInput.placeholder = "1099 3141 1315 13414";
    cardNumberInput.classList.add("irembopay-cardInput", "irembopay-cc-num", "irembopay-input");

    PaymentVal.formatCardNumber(
      document.querySelector("input.irembopay-cc-num")
    );

    cardNumberInput.addEventListener("input", () => {
      document.getElementById("card_type").value = creditCardType(
        cardNumberInput.value.replace(/\s+/g, "").trim()
      );
    });

    var cardBottomSection = cardNumberDetails.appendChild(
      doc.createElement("div")
    );
    cardBottomSection.id = "irembopay-cardBottomSection";

    var cardDateInput = cardBottomSection.appendChild(
      doc.createElement("input")
    );
    cardDateInput.type = "text";
    cardDateInput.inputMode = "numeric";
    cardDateInput.id = "irembopay-cardDate";
    cardDateInput.name = "card_expiry_date";
    cardDateInput.placeholder = "MM / YY";
    cardDateInput.classList.add("irembopay-cc-exp", "irembopay-input");

    cardDateInput.addEventListener("paste", (e) => e.preventDefault());

    PaymentVal.formatCardExpiry(
      document.querySelector("input.irembopay-cc-exp")
    );

    var cardCvvInput = cardBottomSection.appendChild(
      doc.createElement("input")
    );
    cardCvvInput.type = "password";
    cardCvvInput.inputMode = "numeric";
    cardCvvInput.id = "irembopay-cardCvv";
    cardCvvInput.name = "card_cvn";
    cardCvvInput.placeholder = "CVV";
    cardCvvInput.classList.add("irembopay-cc-cvc", "irembopay-input");
    cardCvvInput.autocomplete = "off";

    PaymentVal.formatCardCVC(document.querySelector("input.irembopay-cc-cvc"));

    //card logos
    var cardLogos = cardForm.appendChild(doc.createElement("div"));
    cardLogos.classList.add("irembopay-cardLogos");
    var masterCardlogo = cardLogos.appendChild(doc.createElement("img"));
    masterCardlogo.classList.add("irembopay-cardsImg");
    masterCardlogo.src =
      Actions.getHostingUrl() + "/assets/payment/img/Mastercard.png";
    masterCardlogo.alt = "MasterCard";
    var visaCardlogo = cardLogos.appendChild(doc.createElement("img"));
    visaCardlogo.classList.add("irembopay-cardsImg");
    visaCardlogo.src = Actions.getHostingUrl() + "/assets/payment/img/Visa.png";
    visaCardlogo.alt = "Visa";
    var amexLogo = cardLogos.appendChild(doc.createElement("img"));
    amexLogo.classList.add("irembopay-cardsImg");
    amexLogo.src = Actions.getHostingUrl() + "/assets/payment/img/amex.png";
    amexLogo.alt = "American Express";

    var cardButtons = cardSection.appendChild(doc.createElement("div"));
    cardButtons.classList.add("irembopay-cardButtons");

    var cardError = cardSection.appendChild(doc.createElement("div"));
    cardError.id = "irembopay-cardError";
    cardError.appendChild(
      doc.createTextNode(context["paymentNotReceived"].split(".")[0] + ".")
    );
    cardError.classList.add("irembopay-cardPaymentErrors");

    //Pay button
    var cardPayButton = cardButtons.appendChild(doc.createElement("button"));
    // cardPayButton.type = "submit";
    cardPayButton.id = "irembopay-cardPayBtn";
    cardPayButton.classList.add("irembopay-button", "irembopay-primary-cta");

    cardPayButton.onclick = Payment.onCardSubmission;

    const formattedAmount = data.amount.toLocaleString();

    var cardPayBtnText = cardPayButton.appendChild(doc.createElement("span"));
    cardPayBtnText.classList.add("irembopay-btnText");
    cardPayBtnText.appendChild(
      doc.createTextNode(`${context["pay"]} ${formattedAmount} ${data.currency}`)
    );

    var pciLogo = cardSection.appendChild(doc.createElement("img"));
    pciLogo.classList.add("irembopay-pciImg");
    pciLogo.style.bottom = '24px';
    pciLogo.style.position = 'absolute';
    pciLogo.src = Actions.getHostingUrl() + "/assets/payment/img/pci.svg";
    pciLogo.alt = "Pci";
  }

  static buildMPGSCardSection(alertObj, doc, context, data) {
    var cardSection = alertObj.appendChild(doc.createElement("div"));
    cardSection.id = "irembopay-paymentCardForm";
    cardSection.classList.add("irembopay-cardSectionContainer", "irembopay-hide");

    //form
    var cardForm = cardSection.appendChild(doc.createElement("form"));
    cardForm.id = "irembopay-cardForm";
    cardForm.action = data.formAction;
    cardForm.method = "POST";
    cardForm.classList.add("irembopay-cardForm", "irembopay-margin", "irembopay-padding");
    cardForm.autocomplete = "on";
    // cardForm.onsubmit = Actions.onSubmit(event);

    //authentication parent div
    var authenticationParentDiv = cardSection.appendChild(doc.createElement("div"));
    authenticationParentDiv.id = "authenticationParentDiv";
    authenticationParentDiv.style = "display: none";


    // Authentication container
    var authenticationDiv = authenticationParentDiv.appendChild(doc.createElement("div"));
    authenticationDiv.id = "authenticationDiv";
    authenticationDiv.classList.add("irembopay-alertBox", "irembopay-authenticationDiv");

    // Authentication container
    var threeDSUI = cardSection.appendChild(doc.createElement("div"));
    threeDSUI.id = "3DSUI";
    threeDSUI.style = "display: none";


    var cardShortDescr = cardForm.appendChild(doc.createElement("div"));
    cardShortDescr.classList.add("irembopay-text-bold", "irembopay-margin");
    cardShortDescr.id = "irembopay-cardShortDescr";
    cardShortDescr.innerHTML = context['cardDetailsTitle'];
    cardShortDescr.style = "margin-top:32px"

    var cardNameLabel = cardForm.appendChild(doc.createElement("label"));
    cardNameLabel.for = "irembopay-cardName";
    cardNameLabel.classList.add("irembopay-phoneLabel", "irembopay-label");
    cardNameLabel.appendChild(doc.createTextNode(context['cardHoldersName']));

    var cardNameDiv = cardForm.appendChild(doc.createElement("div"));
    cardNameDiv.classList.add("irembopay-cardNameDiv");
    var cardNameInput = cardNameDiv.appendChild(doc.createElement("input"));
    cardNameInput.type = "text";
    cardNameInput.inputMode = "text";
    cardNameInput.id = "irembopay-cardNameInputMPGS";
    // cardNameInput.name = "card_name";
    cardNameInput.readOnly = "true";
    cardNameInput.required = "true";
    cardNameInput.placeholder = context['nameOnCardPlaceHolder'];
    cardNameInput.classList.add("irembopay-cardInput", "irembopay-input");

    var cardNumberLabel = cardForm.appendChild(doc.createElement("label"));
    cardNumberLabel.for = "irembopay-cardNumber";
    cardNumberLabel.classList.add("irembopay-phoneLabel", "irembopay-label");
    cardNumberLabel.appendChild(doc.createTextNode(context['cardDetails']));

    var cardNumberDetails = cardForm.appendChild(doc.createElement("div"));
    cardNumberDetails.classList.add("irembopay-cardNumberDetails");

    var cardNumberInput = cardNumberDetails.appendChild(doc.createElement("input"));
    cardNumberInput.type = "text";
    cardNumberInput.id = "irembopay-cardNumberMPGS";
    // cardNumberInput.name = "card_number";
    cardNumberInput.readOnly = "true";
    cardNumberInput.required = "true";
    cardNumberInput.pattern = "\d*";
    cardNumberInput.placeholder = "1099 3141 1315 13414";
    cardNumberInput.classList.add("irembopay-cardInput", "irembopay-cc-num", "irembopay-input");

    PaymentVal.formatCardNumber(document.querySelector('input.irembopay-cc-num'));

    cardNumberInput.addEventListener('input', () => {
      document.getElementById("card_type").value = creditCardType(cardNumberInput.value.replace(/\s+/g, '').trim());
    })

    var cardBottomSection = cardNumberDetails.appendChild(doc.createElement("div"));
    cardBottomSection.id = "irembopay-cardBottomSection"

    var leftSection = cardBottomSection.appendChild(doc.createElement("span"));
    leftSection.id = "irembopay-cardLeftSection"
    leftSection.classList.add("irembopay-cardLeftSection")

    var cardMonthInput = leftSection.appendChild(doc.createElement("input"));
    cardMonthInput.type = "text";
    cardMonthInput.inputMode = "numeric";
    cardMonthInput.id = "irembopay-cardMonthMPGS";
    // cardDateInput.name = "card_expiry_date";
    cardMonthInput.readOnly = "true";

    cardMonthInput.placeholder = "MM";
    cardMonthInput.classList.add("irembopay-cc-exp", "irembopay-input");

    cardMonthInput.addEventListener('paste', e => e.preventDefault());

    PaymentVal.formatCardExpiry(document.querySelector('input.irembopay-cc-exp'));
    var cardYearInput = leftSection.appendChild(doc.createElement("input"));
    cardYearInput.type = "text";
    cardYearInput.inputMode = "numeric";
    cardYearInput.id = "irembopay-cardYearMPGS";
    // cardDateInput.name = "card_expiry_date";
    cardYearInput.readOnly = "true";
    cardYearInput.placeholder = "YY";
    cardYearInput.classList.add("irembopay-cc-exp");

    cardYearInput.addEventListener('paste', e => e.preventDefault());

    PaymentVal.formatCardExpiry(document.querySelector('input.irembopay-cc-exp'));

    var cardCvvInput = cardBottomSection.appendChild(doc.createElement("input"));
    cardCvvInput.type = "password";
    cardCvvInput.inputMode = "numeric";
    cardCvvInput.id = "irembopay-cardCvvMPGS";
    // cardCvvInput.name = "card_cvn";
    cardCvvInput.readOnly = "true";
    cardCvvInput.required = "true";
    cardCvvInput.placeholder = "CVV";
    cardCvvInput.classList.add("irembopay-cc-cvc", "irembopay-input");
    cardCvvInput.autocomplete = "off";

    PaymentVal.formatCardCVC(document.querySelector('input.irembopay-cc-cvc'));

    //card logos
    var cardLogos = cardForm.appendChild(doc.createElement("div"));
    cardLogos.classList.add("irembopay-cardLogos");
    var masterCardlogo = cardLogos.appendChild(doc.createElement("img"));
    masterCardlogo.classList.add("irembopay-cardsImg");
    masterCardlogo.src = Actions.getHostingUrl() + "/assets/payment/img/Mastercard.png";
    masterCardlogo.alt = "MasterCard";
    var visaCardlogo = cardLogos.appendChild(doc.createElement("img"));
    visaCardlogo.classList.add("irembopay-cardsImg");
    visaCardlogo.src = Actions.getHostingUrl() + "/assets/payment/img/Visa.png";
    visaCardlogo.alt = "Visa";

    // var amexLogo = cardLogos.appendChild(doc.createElement("img"));
    // amexLogo.classList.add("irembopay-cardsImg");
    // amexLogo.src = Actions.getHostingUrl() + "/assets/payment/img/amex.png";
    // amexLogo.alt = "American Express";

    var cardButtons = cardSection.appendChild(doc.createElement("div"));
    cardButtons.classList.add("irembopay-cardButtons");

    var cardError = cardSection.appendChild(doc.createElement("div"));
    cardError.id = "irembopay-cardError";
    cardError.appendChild(doc.createTextNode(context['paymentNotReceived'].split('.')[0] + "."));
    cardError.classList.add("irembopay-cardPaymentErrors");

    //Pay button
    var cardPayButton = cardButtons.appendChild(doc.createElement("button"));
    // cardPayButton.type = "submit";
    cardPayButton.id = "irembopay-cardPayBtn";
    cardPayButton.classList.add("irembopay-button", "irembopay-primary-cta");

    cardPayButton.onclick = Payment.onCardSubmissionMPGS;

    const formattedAmount = data.mpgsProfileDto.amount.toLocaleString();

    var cardPayBtnText = cardPayButton.appendChild(doc.createElement("span"));
    cardPayBtnText.classList.add("irembopay-btnText");
    cardPayBtnText.appendChild(
      doc.createTextNode(`${context['pay']} ${formattedAmount} ${data.mpgsProfileDto.currency}`)
    );

    var pciLogo = cardSection.appendChild(doc.createElement("img"));
    pciLogo.classList.add("irembopay-pciImg");
    pciLogo.src = Actions.getHostingUrl() + "/assets/payment/img/pci.svg";
    pciLogo.alt = "Pci";
  }

  static setValueFromPaymentOption(value, isMPGS = false) {
    Actions.changePayVia(value, paymentData.invoiceNumber, paymentData.currency, paymentData.amount, isMPGS);
    PAYMENT_METHOD = value;
    Actions.executeAndCatchError(() => {
      document.getElementById("irembopay-shortCodeDescr").innerHTML =
        this.getShortDescText(value);
    });
    Actions.executeAndCatchError(() => {
      document.getElementById("irembopay-providerLogo").src =
        this.getProviderLogo(value);
    });
    Actions.executeAndCatchError(() => {
      document.getElementById("irembopay-momoInfo").innerHTML =
        this.getMomoInputLabelText(value);
    });
    Actions.executeAndCatchError(() => {
      document.getElementById("irembopay-ussdCode").innerText =
        this.getUssdCode(value);
    });

    Actions.executeAndCatchError(() => {
      document.getElementById("irembopay-phone").placeholder =
        this.getPlacePhoneHolders(value);
      document.getElementById("irembopay-phone").disabled = false;
    });

    Actions.executeAndCatchError(() => {
      document.getElementById("irembopay-momoInfoLabel").innerHTML =
        this.getPayGuideText(value);
    });
  }

  static createMomoPullSection(momoContainer, doc, data) {
    if (SHOULD_SHOW_PULL_SECTION) {
      var shortCode = momoContainer.appendChild(doc.createElement("div"));
      shortCode.classList.add("irembopay-shortCode");
      var shortCodeDescr = shortCode.appendChild(doc.createElement("div"));
      shortCodeDescr.classList.add(
        "irembopay-shortCodeDescr",
        "irembopay-text-bold"
      );
      shortCodeDescr.id = "irembopay-shortCodeDescr";
      shortCodeDescr.innerHTML = this.getShortDescText("irembopay-momo");

      var shortCodeMTN = shortCode.appendChild(doc.createElement("div"));
      shortCodeMTN.classList.add("irembopay-shortCodeMTN");

      var providerLogo = shortCodeMTN.appendChild(doc.createElement("img"));
      providerLogo.classList.add("irembopay-shortCodeImg");
      providerLogo.id = "irembopay-providerLogo";
      providerLogo.src = this.getProviderLogo("irembopay-momo");

      var shortCodeMTNlabel = shortCodeMTN.appendChild(
        doc.createElement("div")
      );
      shortCodeMTNlabel.classList.add(
        "irembopay-shortCodelabel",
        "irembopay-text-bold"
      );

      var ussdCode = doc.createElement("div");
      ussdCode.id = "irembopay-ussdCode";
      ussdCode.innerText = this.getUssdCode("irembopay-momo");

      shortCodeMTNlabel.appendChild(ussdCode);
      var billIdforShortCode = shortCodeMTNlabel.appendChild(
        doc.createElement("span")
      );

      billIdforShortCode.classList.add("irembopay-billIdforShortCode");
      billIdforShortCode.appendChild(doc.createTextNode(data.invoiceNumber));
      shortCodeMTNlabel.appendChild(doc.createTextNode("#"));

      var linebreak = momoContainer.appendChild(doc.createElement("hr"));
      linebreak.classList.add("line-break-mobile");
      momoContainer.appendChild(linebreak);
    }
  }

  static buildOfflineSection(alertObj, doc, context, data) {
    try {
      var offlineFormContainer = alertObj.appendChild(doc.createElement("div"));
      offlineFormContainer.id = "irembopay-offlineFormContainer";
      offlineFormContainer.classList.add(
        "irembopay-offline-container",
        "irembopay-hide",
        "irembopay-margin",
        "irembopay-padding"
      );

      var offlineSectionText = context["offline"].replace(
        "<id>",
        `<span class='irembopay-text-teal irembopay-text-bold'>${data.invoiceNumber}</span>`
      );

      var textNode = doc.createElement("p");
      textNode.innerHTML = offlineSectionText;

      offlineFormContainer.appendChild(textNode);

      var offlineFormContainerPaymentChoices =
        context["offlineChoices"].split("|");
      var ul = offlineFormContainer.appendChild(doc.createElement("ul"));
      ul.classList.add("irembopay-bank-accounts-list")

      for (var choice of offlineFormContainerPaymentChoices) {
        var li = ul.appendChild(doc.createElement("li"));
        var img = document.createElement("img");
        img.src = this.getBankProviderLogo(choice?.toLowerCase());
        img.alt = "Logo";
        img.style.width = "10%";
        img.style.height = "10%";
        img.style.marginRight = "10px";
        li.appendChild(img);
        li.appendChild(document.createTextNode(choice.trim()));
      }

      var pciLogo = offlineFormContainer.appendChild(doc.createElement("img"));
      pciLogo.classList.add("irembopay-pciImg");
      pciLogo.style.bottom = '24px';
      pciLogo.style.position = 'absolute';
      pciLogo.src = Actions.getHostingUrl() + "/assets/payment/img/pci.svg";
      pciLogo.alt = "Pci";
    } catch (_) { }
  }

  static buildBankAccountSection(alertObj, doc, context, data) {
    try {
      var bankSection = alertObj.appendChild(doc.createElement("div"));
      bankSection.id = "irembopay-bank-section";
      bankSection.classList.add(
        "irembopay-offline-container",
        "irembopay-hide",
        "irembopay-margin",
        "irembopay-padding"
      );

      var bankSectionText = context["bank"].replace(
        "<id>",
        `<span class='irembopay-text-teal irembopay-text-bold'>${data.invoiceNumber}</span>`
      );

      var textNode = doc.createElement("p");
      textNode.innerHTML = bankSectionText;
      bankSection.appendChild(textNode);

      var bankSectionPaymentChoices = context["bankChoices"].split("|");
      var ul = bankSection.appendChild(doc.createElement("ul"));
      ul.classList.add("irembopay-bank-accounts-list")

      for (var choice of bankSectionPaymentChoices) {
        var li = ul.appendChild(doc.createElement("li"));
        var img = document.createElement("img");
        img.src = this.getBankProviderLogo(choice?.toLowerCase());
        img.alt = "Logo";
        img.style.width = "10%";
        img.style.height = "10%";
        img.style.marginRight = "10px";
        li.appendChild(img);
        li.appendChild(document.createTextNode(choice.trim()));
      }

      var pciLogo = bankSection.appendChild(doc.createElement("img"));
      pciLogo.classList.add("irembopay-pciImg");
      pciLogo.style.bottom = '24px';
      pciLogo.style.position = 'absolute';
      pciLogo.src = Actions.getHostingUrl() + "/assets/payment/img/pci.svg";
      pciLogo.alt = "Pci";
    } catch (_) { }
  }

  static createBankTransferUpdateSection(bankTransfer, invoiceDetails, collectionAccount) {
    var alertObject = document.getElementById("irembopay-alertBox");
    var bankTransferUpdateSection = alertObject.appendChild(document.createElement("div"));
    bankTransferUpdateSection.id = "irembopay-bank-transfer-update-section";
    bankTransferUpdateSection.classList.add("irembopay-show", "irembopay-margin", "irembopay-padding");

    var bankDetailsTitle = bankTransferUpdateSection.appendChild(document.createElement("div"));
    bankDetailsTitle.classList.add("irembopay-text-bold", "irembopay-margin");
    bankDetailsTitle.id = "bank-details-title";
    bankDetailsTitle.innerHTML = context['initiateTransfer'];

    var bankTransferUpdateSectionDescr = bankTransferUpdateSection.appendChild(document.createElement("p"));
    var bankTransferUpdateSectionDescrText = context['transferUpdateDescription'];
    bankTransferUpdateSectionDescr.innerHTML = bankTransferUpdateSectionDescrText

    var bankDetailsContent = document.createElement('div');
    bankDetailsContent.classList.add('bank-details-content', 'afterContent');
    bankTransferUpdateSection.appendChild(bankDetailsContent);

    var collectionAccountDetails = {
      bankName: {
        label: context['bankName'],
        value: collectionAccount.bank
      },
      accountName: {
        label: context['accountName'],
        value: collectionAccount.accountName
      },
      accountNumber: {
        label: context['accountNumber'],
        value: HtmlBuilder.readableAccountNumber(collectionAccount.accountNumber)
      },
      narration: {
        label: context['narration'],
        value: invoiceDetails.invoiceNumber
      },
      amount: {
        label: context['amount'],
        value: invoiceDetails.amount,
        currency: invoiceDetails.currency
      }
    }

    HtmlBuilder.generateCollectionAccountDisplay(collectionAccountDetails, bankDetailsContent, collectionAccount.logoLink)

    var afterTransferDisclaimer = bankTransferUpdateSection.appendChild(document.createElement("div"));
    afterTransferDisclaimer.classList.add("transfer-disclaimer")

    var disclaimerMessage = afterTransferDisclaimer.appendChild(document.createElement("div"));
    disclaimerMessage.classList.add("disclaimer-message");
    disclaimerMessage.innerHTML = context['acknowledgement']

    var bankTransferUpdateSectionDisclaimer = bankTransferUpdateSection.appendChild(document.createElement("p"));
    var bankTransferUpdateSectionDisclaimerText = context['transferUpdateDisclaimer']
    bankTransferUpdateSectionDisclaimer.innerHTML = bankTransferUpdateSectionDisclaimerText;
    var buttonDiv = bankTransferUpdateSection.appendChild(document.createElement("div"));
    buttonDiv.classList.add("row");

    var updateButton = buttonDiv.appendChild(document.createElement("button"));
    updateButton.id = "irembopay-transferUpdateBtn";
    updateButton.textContent = context['update']
    updateButton.classList.add("irembopay-button", "edtBtn", "col-12")

    var bankTransferProceedToPayButton = buttonDiv.appendChild(document.createElement("button"));
    bankTransferProceedToPayButton.id = "irembopay-bankTransferProceedToPayBtn";
    bankTransferProceedToPayButton.textContent = context['bankTransferProceedToPay']
    bankTransferProceedToPayButton.classList.add("irembopay-button", "irembopay-primary-cta", "col-12")

    updateButton.addEventListener("click", () => {
      SHOULD_UPDATE_TRANSFER = true;
      bankTransferUpdateSection.classList.remove("irembopay-show");
      bankTransferUpdateSection.classList.add("irembopay-hide");
      const bankTransferSection = document.getElementById("irembopay-bank-transfer-section");
      bankTransferSection.classList.add("irembopay-show");
      var bankSelect = document.getElementById("irembopay-bank-selector");
      bankSelect.value = bankTransfer.bank.swiftCode
      document.getElementById("irembopay-account-name-input").value = bankTransfer.accountName;
      var changeEvent = new Event('change');
      bankSelect.dispatchEvent(changeEvent);
    });

    bankTransferProceedToPayButton.addEventListener("click", () => {
      var selectedBank = banks.find(bank => bank.swiftCode === bankTransfer.bank.swiftCode);
      Payment.trackEvent("Bank Transfer Payment - Proceed to Pay", {
        "invoiceNumber": Payment.IREMBOPAY_VERIFICATION_DATA.invoiceNumber,
        "currency": Payment.IREMBOPAY_VERIFICATION_DATA.currency,
        "merchant": Payment.IREMBOPAY_VERIFICATION_DATA?.appAccount.name,
        "paymentMethod": "Bank Transfer",
        "bank": selectedBank.name
      })
      window.open(selectedBank.internetBankingUrl, '_blank');
    })

    var pciLogo = bankTransferUpdateSection.appendChild(document.createElement("img"));
    pciLogo.classList.add("irembopay-pciImg");
    pciLogo.src = Actions.getHostingUrl() + "/assets/payment/img/pci.svg";
    pciLogo.alt = "Pci";
  }

  static buildBankTransferSection(modalObject, alertObj, doc, context, data) {
    try {
      var bankTransferSection = alertObj.appendChild(doc.createElement("div"));
      bankTransferSection.id = "irembopay-bank-transfer-section";
      bankTransferSection.classList.add("irembopay-hide", "irembopay-margin", "irembopay-padding");

      var bankTransferTitle = bankTransferSection.appendChild(doc.createElement("div"));
      bankTransferTitle.classList.add("irembopay-text-bold", "irembopay-margin");
      bankTransferTitle.id = "bank-transfer-title";
      bankTransferTitle.innerHTML = context['shareBankDetails'];

      var bankTransferDescription = bankTransferSection.appendChild(doc.createElement("p"))
      bankTransferDescription.innerHTML = context['whatToProvide']

      var amountDisplay = bankTransferSection.appendChild(doc.createElement("div"));
      amountDisplay.classList.add("irembopay-text-bold", "irembopay-margin");
      amountDisplay.id = "bank-transfer-amount";
      amountDisplay.innerHTML = context['amount'] + ": " + data.amount.toLocaleString() + " " + data.currency;

      //Bank Selector
      this.generateBankSelector(bankTransferSection, doc, context['selectBank'])

      //Account Name
      this.generateLabeledInput(bankTransferSection, doc, context['accountName'], "text", "text", "irembopay-account-name-input", "account-name", "Account Name", false)

      //Account Number
      this.generateLabeledInput(bankTransferSection, doc, context['accountNumber'], "text", "text", "irembopay-account-number-input", "account-number", "1234-5678-9000", false)

      // Account number format for BK
      this.generateAccountNumberFormatAlert(bankTransferSection, doc, context['transferNumberFormatAlert']);

      doc.getElementById("irembopay-account-number-input").addEventListener('input', (event) => {
        let value = event.target.value.replace(/\D/g, '');
        if (value.length > 25) {
          value = value.slice(0, 25);
        }
        let formattedValue = '';
        for (let i = 0; i < value.length; i++) {
          if (i % 4 === 0 && i !== 0) {
            formattedValue += '-';
          }
          formattedValue += value[i];
        }
        event.target.value = formattedValue;

        var selectedBank = doc.getElementById('irembopay-bank-selector').value;
        if (selectedBank != 'BKIGRWRW') {
          doc.getElementById('irembopay-bank-number-format-alert').style = 'display: none';
          doc.getElementById('irembopay-transferProceedBtn').disabled = false;
        }
      });

      var buttonDiv = bankTransferSection.appendChild(doc.createElement("div"));
      buttonDiv.classList.add("irembopay-bankTransferButtons");

      //Proceed button
      var proceedButton = buttonDiv.appendChild(doc.createElement("button"));
      proceedButton.id = "irembopay-transferProceedBtn";
      proceedButton.classList.add("irembopay-button", "irembopay-primary-cta");

      var errorDiv = bankTransferSection.appendChild(doc.createElement("div"));
      errorDiv.id = "bank-transfer-error"
      errorDiv.classList.add("bank-transfer-error");
      var errorText = errorDiv.appendChild(doc.createElement("text"));
      errorText.textContent = context['missingDetails'];

      proceedButton.onclick = function () {
        var amount = data.amount;
        var bankSelect = doc.getElementById("irembopay-bank-selector");
        var bankName = bankSelect.options[bankSelect.selectedIndex].text;
        var accountName = doc.getElementById("irembopay-account-name-input").value;
        var accountNumber = doc.getElementById("irembopay-account-number-input").value;

        if (HtmlBuilder.validateValues(amount, bankName, accountName, accountNumber)) {
          errorDiv.classList.remove("show");
          HtmlBuilder.createVerificationModal(modalObject, amount, bankName, accountName, accountNumber, data.currency)
          HtmlBuilder.showVerificationBox()
        } else {
          errorDiv.classList.add("show");
        }
      }

      var proceedBtnText = proceedButton.appendChild(doc.createElement("span"));
      proceedBtnText.classList.add("irembopay-btnText");
      proceedBtnText.appendChild(
        doc.createTextNode(context['proceed'])
      );

      var afterBankTransferSection = alertObj.appendChild(doc.createElement("div"));
      afterBankTransferSection.id = "irembopay-after-bank-transfer-section";
      afterBankTransferSection.classList.add("irembopay-hide", "irembopay-margin", "irembopay-padding");

      var sectionTitle = bankTransferTitle.cloneNode(true);

      afterBankTransferSection.appendChild(sectionTitle);
      var afterBankTransferSectionDescr = afterBankTransferSection.appendChild(doc.createElement("p"));

      proceedButton.addEventListener("click", () => {
        var afterBankTransferSectionDescrText = context['bankTransferSummary'];
        afterBankTransferSectionDescr.innerHTML = afterBankTransferSectionDescrText
      });

      var transferDetails

      Payment.getCollectionAccount(data.currency)
        .then((res) => {
          if (res.success) {
            var collectionAccount = res.data
            transferDetails = {
              bankName: {
                label: context['bankName'],
                value: collectionAccount.bank
              },
              accountName: {
                label: context['accountName'],
                value: collectionAccount.accountName
              },
              accountNumber: {
                label: context['accountNumber'],
                value: HtmlBuilder.readableAccountNumber(collectionAccount.accountNumber)
              },
              narration: {
                label: context['narration'],
                value: data.invoiceNumber
              },
              amount: {
                label: context['amount'],
                value: data.amount,
                currency: data.currency
              }
            };

            HtmlBuilder.generateCollectionAccountDisplay(transferDetails, bankDetailsContent, collectionAccount.logoLink)
          }
        })

      var bankDetailsContent = doc.createElement('div');
      bankDetailsContent.classList.add('bank-details-content', 'afterContent');
      afterBankTransferSection.appendChild(bankDetailsContent);

      var afterTransferDisclaimer = afterBankTransferSection.appendChild(doc.createElement("div"));
      afterTransferDisclaimer.classList.add("transfer-disclaimer")


      var disclaimerMessage = afterTransferDisclaimer.appendChild(doc.createElement("div"));
      disclaimerMessage.classList.add("disclaimer-message");
      disclaimerMessage.innerHTML = context['acknowledgement'];

      var bankTransferUpdateSectionDisclaimer = afterBankTransferSection.appendChild(document.createElement("p"));
      var bankTransferUpdateSectionDisclaimerText = context['transferUpdateDisclaimer']
      bankTransferUpdateSectionDisclaimer.innerHTML = bankTransferUpdateSectionDisclaimerText;


      var buttonDiv = bankTransferUpdateSectionDisclaimer.appendChild(document.createElement("div"));
      buttonDiv.classList.add("row");

      var updateButton = buttonDiv.appendChild(document.createElement("button"));
      updateButton.id = "irembopay-transferUpdateBtn";
      updateButton.textContent = context['update']
      updateButton.classList.add("irembopay-button", "edtBtn", "col-12")

      var bankTransferProceedToPayButton = buttonDiv.appendChild(document.createElement("button"));
      bankTransferProceedToPayButton.id = "irembopay-bankTransferProceedToPayBtn";
      bankTransferProceedToPayButton.textContent = context['bankTransferProceedToPay']
      bankTransferProceedToPayButton.classList.add("irembopay-button", "irembopay-primary-cta", "col-12")

      var bankTransferUpdateSection = document.getElementById('irembopay-after-bank-transfer-section');
      updateButton.addEventListener("click", () => {
        SHOULD_UPDATE_TRANSFER = true;
        bankTransferUpdateSection.classList.remove("irembopay-show");
        bankTransferUpdateSection.classList.add("irembopay-hide");
        const bankTransferSection = document.getElementById("irembopay-bank-transfer-section");
        bankTransferSection.classList.add("irembopay-show");
      });

      bankTransferProceedToPayButton.addEventListener("click", () => {
        var bankSelect = document.getElementById("irembopay-bank-selector").value;
        var selectedBank = banks.find(bank => bank.swiftCode === bankSelect);
        Payment.trackEvent("Bank Transfer Payment - Proceed to Pay", {
          "invoiceNumber": Payment.IREMBOPAY_VERIFICATION_DATA.invoiceNumber,
          "currency": Payment.IREMBOPAY_VERIFICATION_DATA.currency,
          "merchant": Payment.IREMBOPAY_VERIFICATION_DATA?.appAccount.name,
          "paymentMethod": "Bank Transfer",
          "bank": selectedBank.name
        })
        window.open(selectedBank.internetBankingUrl, '_blank');
      })
    } catch (_) {
      console.log(_)
    }
  }

  static generateCollectionAccountDisplay(transferDetails, parent, logoLink) {
    var doc = document;
    for (let key in transferDetails) {
      // Create a div for the property
      var singleDetailDiv = doc.createElement("div");
      singleDetailDiv.classList.add('single-detail-div');

      var keyDiv = doc.createElement("div")
      keyDiv.classList.add("key-div")
      keyDiv.textContent = transferDetails[key].label + ": "
      keyDiv.style.fontWeight = "bold"
      singleDetailDiv.appendChild(keyDiv);

      var valueDiv = doc.createElement("div")

      if (key == "bankName") {
        var bankLogo = doc.createElement("img");
        bankLogo.classList.add('bank-logo')
        bankLogo.src = logoLink;
        valueDiv.appendChild(bankLogo);
      }

      var valueText = doc.createElement("span")
      if (key == "amount") {
        valueText.innerHTML = transferDetails[key].value.toLocaleString();
      } else {
        valueText.innerHTML = transferDetails[key].value
      }
      valueDiv.appendChild(valueText)

      if (key != "bankName") {
        if (key == "amount") {
          var currencyLabel = doc.createElement("span");
          currencyLabel.innerHTML = ' ' + transferDetails[key].currency;
          valueDiv.appendChild(currencyLabel);
        }

        var iconWrapper = doc.createElement("a");
        iconWrapper.title = "Copy";
        iconWrapper.href = "#";
        iconWrapper.classList.add("copy-icon-wrapper");

        var copyIcon = doc.createElement("i");
        copyIcon.className = "fa fa-copy";
        copyIcon.classList.add("copy-icon");
        copyIcon.style.fontSize = "15px";
        copyIcon.addEventListener('click', function (event) {
          event.preventDefault();
          navigator.clipboard.writeText("").then(function () {
            navigator.clipboard.writeText(transferDetails[key].value);
          })
        });

        iconWrapper.appendChild(copyIcon);
        valueDiv.appendChild(iconWrapper);
      }

      valueDiv.classList.add("value-div");
      valueDiv.style.flex = 1.5;

      singleDetailDiv.appendChild(valueDiv);

      // Append the property div to the container
      parent.appendChild(singleDetailDiv);
    }
  }
  static validateValues(amount, bankName, accountName, accountNumber) {
    if (amount === "" || bankName === "" || accountName === "" || accountNumber === "" || accountNumber.length < 7) {
      return false;
    }
    return true;
  }

  static createVerificationModal(modalObject, amount, bankName, accountName, accountNumber, currency) {

    var verificationModalId = "irembopay-verification-modal"
    if (document.getElementById(verificationModalId)) {
      return;
    }
    var doc = document;
    var verificationModal = modalObject.appendChild(doc.createElement("div"));
    verificationModal.id = verificationModalId;
    verificationModal.classList.add("irembopay-verification-modal", "boxShadoweffect")
    verificationModal.style.display = "block";

    // Create the modal content container
    const modalContent = doc.createElement('div');
    modalContent.classList.add('verfication-modal-content');

    const contentHeader = doc.createElement('div');
    contentHeader.classList.add('verfication-modal-content-header');
    modalContent.appendChild(contentHeader);

    var icon = doc.createElement("img");
    icon.width = 50;
    icon.height = 50;
    icon.src = "https://res.cloudinary.com/irembopay/image/upload/v1729062757/attention_a2af0r_kbu43n.png"

    var closeButton = doc.createElement("div");
    closeButton.innerHTML = 'X';
    closeButton.id = 'irembopay-modalCloseButton';

    closeButton.addEventListener("click", () => {
      verificationModal.style.display = "none";
      modalObject.removeChild(verificationModal);
    });

    contentHeader.appendChild(icon);

    contentHeader.appendChild(closeButton);

    const contentDescription = doc.createElement('div');
    contentDescription.classList.add('verfication-modal-content-description');
    modalContent.appendChild(contentDescription);

    var descriptionTitle = doc.createElement('h3')
    descriptionTitle.appendChild(doc.createTextNode(context['attention']))
    descriptionTitle.style.color = "#dc6903";
    descriptionTitle.style.fontWeight = "bold";
    contentDescription.appendChild(descriptionTitle)

    var description = document.createElement('span')
    description.appendChild(doc.createTextNode(context['attentionDescription']))
    description.style.fontWeight = "thin";
    description.style.color = "#828c99";
    contentDescription.appendChild(description)

    var bankDetails = {
      amount: {
        label: context['amount'],
        value: amount.toLocaleString() + " " + currency
      },
      bankName: {
        label: context['bankName'],
        value: bankName
      },
      accountName: {
        label: context['accountName'],
        value: accountName
      },
      accountNumber: {
        label: context['accountNumber'],
        value: accountNumber
      }
    };


    var bankDetailsContent = doc.createElement('div');
    bankDetailsContent.classList.add('bank-details-content');
    modalContent.appendChild(bankDetailsContent);

    for (var key in bankDetails) {
      // Create a div for the property
      var singleDetailDiv = document.createElement("div");
      singleDetailDiv.classList.add('single-detail-div')

      var keyDiv = document.createElement("div")
      keyDiv.classList.add("key-div")
      keyDiv.textContent = bankDetails[key].label + ": "
      singleDetailDiv.appendChild(keyDiv)

      var valueDiv = document.createElement("div")
      valueDiv.classList.add("value-div")
      valueDiv.textContent = bankDetails[key].value
      valueDiv.style.fontWeight = "bold"
      singleDetailDiv.appendChild(valueDiv)

      // Append the property div to the container
      bankDetailsContent.appendChild(singleDetailDiv);
    }


    var verificationButtons = doc.createElement("div");
    verificationButtons.classList.add("verification-buttons");
    modalContent.appendChild(verificationButtons);

    var editButton = doc.createElement('button');
    editButton.textContent = context['edit']
    editButton.classList.add("irembopay-button", "edtBtn")
    verificationButtons.appendChild(editButton);

    editButton.addEventListener("click", () => {
      verificationModal.style.display = "none";
      modalObject.removeChild(verificationModal);
    });

    var confirmButton = doc.createElement('button');
    confirmButton.textContent = context['confirm']
    confirmButton.classList.add("irembopay-button", "cnfrmBtn", "irembopay-primary-cta")
    verificationButtons.appendChild(confirmButton);

    confirmButton.addEventListener("click", () => {
      confirmButton.disabled = true;
      editButton.disabled = true;
      confirmButton.innerHTML = '<div class="loader-container"><div class="loader"></div></div>';
      var swiftCode = doc.getElementById("irembopay-bank-selector").value;
      var accountName = doc.getElementById("irembopay-account-name-input").value;
      var bank = banks.find(bank => bank.swiftCode === swiftCode).name;
      var accountNumber = doc.getElementById("irembopay-account-number-input").value;

      var payload = {
        provider: HtmlBuilder.getPaymentMethodHash()["irembopay-bank-transfer"],
        swiftCode,
        accountNumber: HtmlBuilder.formatAccountNumber(accountNumber),
        accountName,
        bank
      }

      Payment.sendBankTransfer(payload, SHOULD_UPDATE_TRANSFER)
        .then(() => {
          verificationModal.style.display = "none";
          modalObject.removeChild(verificationModal);

          var sectionToGo = doc.getElementById("irembopay-bank-transfer-section");
          sectionToGo.classList.remove("irembopay-show");
          sectionToGo.classList.add("irembopay-hide");

          var nextScreen = doc.getElementById("irembopay-after-bank-transfer-section");
          nextScreen.classList.remove("irembopay-hide");
          nextScreen.classList.add("irembopay-show");
        }).catch(() => {
          verificationModal.style.display = "none";
          modalObject.removeChild(verificationModal);
          var errorDiv = doc.getElementById("bank-transfer-error");
          errorDiv.removeChild(errorDiv.firstChild);
          var errorText = errorDiv.appendChild(doc.createElement("text"));
          errorText.textContent = context['transferError'];
          errorDiv.classList.add("show");
        });
    });

    verificationModal.appendChild(modalContent);
  }

  static formatAccountNumber(accountNumber) {
    return accountNumber.replace(/-/g, '').trim();
  }

  static readableAccountNumber(accountNumber) {
    return accountNumber.replace(/(.{4})/g, '$1 ').trim();
  }

  static formatCamelCaseString(str) {
    return str.replace(/([A-Z])/g, ' $1').trim();
  }


  static generateLabeledInput(parent, doc, labelText, inputType, inputMode, inputId, inputName, inputPlaceholder, disabled, inputValue) {
    var label = parent.appendChild(doc.createElement("label"));
    label.for = inputId;
    label.classList.add("irembopay-phoneLabel", "irembopay-label");
    label.innerHTML = labelText + `<span style="color: red;"> *</span>`;

    var div = parent.appendChild(doc.createElement("div"));
    div.classList.add("irembopay-div");
    var input = div.appendChild(doc.createElement("input"));
    input.type = inputType;
    input.inputMode = inputMode;
    input.id = inputId;
    input.name = inputName;
    input.disabled = disabled;
    input.placeholder = inputPlaceholder
    if (inputValue) input.value = inputValue;
    input.classList.add("irembopay-cardInput", "irembopay-input");
  }

  static generateAccountNumberFormatAlert(parent, doc, alertMessage) {
    var alertContainer = parent.appendChild(doc.createElement("div"));
    alertContainer.id = 'irembopay-bank-number-format-alert';
    alertContainer.classList.add('bank-transfer-number-format-container');

    var alertIcon = alertContainer.appendChild(doc.createElement("span"));
    alertIcon.innerHTML = `<svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.99988 7.49995V10.8333M9.99988 14.1666H10.0082M8.84598 3.24305L1.9919 15.0819C1.61173 15.7386 1.42165 16.0669 1.44974 16.3364C1.47425 16.5714 1.59739 16.785 1.78852 16.924C2.00765 17.0833 2.38704 17.0833 3.14581 17.0833H16.8539C17.6127 17.0833 17.9921 17.0833 18.2112 16.924C18.4024 16.785 18.5255 16.5714 18.55 16.3364C18.5781 16.0669 18.388 15.7386 18.0078 15.0819L11.1538 3.24305C10.775 2.58875 10.5856 2.26159 10.3385 2.15172C10.1229 2.05587 9.87684 2.05587 9.66129 2.15172C9.41419 2.26159 9.22478 2.58875 8.84598 3.24305Z" stroke="#DC6803" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>`;

    var alertText = alertContainer.appendChild(doc.createElement("p"));
    alertText.style = 'display: inline';
    alertText.innerHTML = alertMessage;

    alertContainer.appendChild(alertIcon);
    alertContainer.appendChild(alertText);
  }

  static generateBankSelector(parent, doc, labelText) {
    var label = parent.appendChild(doc.createElement("label"));
    label.classList.add("irembopay-phoneLabel", "irembopay-label");
    label.innerHTML = labelText + `<span style="color: red;"> *</span>`;

    var div = parent.appendChild(doc.createElement("div"));
    div.classList.add("irembopay-div");

    const bankSelect = doc.createElement('select');
    bankSelect.id = "irembopay-bank-selector"
    bankSelect.classList.add("form-control");
    bankSelect.classList.add("irembopay-phone");

    div.appendChild(bankSelect);

    Payment.getBanks()
      .then((res) => {
        if (res.success) {
          res.data.results.forEach(result => {
            banks.push({
              name: result.name,
              swiftCode: result.swiftCode,
              internetBankingUrl: result.internetBankingUrl
            });
          });

          banks.forEach(bank => {
            const option = doc.createElement('option');
            option.value = bank.swiftCode;
            option.text = bank.name;
            bankSelect.appendChild(option);
          });
        }
      }).catch((error) => {
        console.log(error);
      });

    bankSelect.addEventListener('change', event => {
      const bankAccountNumberInput = doc.getElementById("irembopay-account-number-input");
      if (event.target.value == 'BKIGRWRW') {
        bankAccountNumberInput.inputEventHandler = function (e) {
          addAccountNumberValidation(e, document);
        };
        bankAccountNumberInput.addEventListener("input", bankAccountNumberInput.inputEventHandler);

        var inputEvent = new Event('input');
        bankAccountNumberInput.dispatchEvent(inputEvent); // Dispatch the 'input' event
      } else {
        // Remove the 'input' event listener if it exists
        if (bankAccountNumberInput.inputEventHandler) {
          bankAccountNumberInput.removeEventListener("input", bankAccountNumberInput.inputEventHandler);
          bankAccountNumberInput.inputEventHandler = null;
        }
        doc.getElementById('irembopay-bank-number-format-alert').style = 'display: none';
        doc.getElementById('irembopay-transferProceedBtn').disabled = false;
      }
    });

    function addAccountNumberValidation(event, doc) {
      let accountNumberValue = event.target.value.replace(/\D/g, '');

      const isValid = /^\d{12}$/.test(accountNumberValue);

      if (isValid) {
        doc.getElementById('irembopay-bank-number-format-alert').style = 'display: none';
        doc.getElementById('irembopay-transferProceedBtn').disabled = false;
      }
      else {
        doc.getElementById('irembopay-transferProceedBtn').disabled = true;
        doc.getElementById('irembopay-bank-number-format-alert').style = 'display: block;';
      }
    }
  }

  static momoInputSection(momoContainer, data) {
    var momoForm = momoContainer.appendChild(document.createElement("div"));
    momoForm.id = "irembopay-momoForm";
    momoForm.classList.add("irembopay-momoForm");
    var phoneInputLabel = momoForm.appendChild(document.createElement("label"));
    phoneInputLabel.for = "irembopay-phone";
    phoneInputLabel.classList.add("irembopay-label");
    var phoneInput = momoForm.appendChild(document.createElement("input"));
    phoneInput.type = "tel";
    phoneInput.id = "irembopay-phone";
    phoneInput.name = "phone";
    phoneInput.placeholder = "ex: 078/9xxxxxxx";
    phoneInput.classList.add("irembopay-phone");
    phoneInput.onkeyup = this.initiatePhoneValidation;

    const formattedAmount = data.amount.toLocaleString();

    var payButton = HtmlBuilder.createButton(
      "irembopay-payBtn",
      `${context["pay"]} ${formattedAmount} ${data.currency}`,
      HtmlBuilder.initiateAction
    );
    momoForm.appendChild(payButton);
    // Actions.disableButtonToggle("irembopay-payBtn", true);
  }

  static createElement({ parent, id, type }) {
    var doc = document;
    var element = parent.appendChild(doc.createElement(type));
    element.id = id;
    return element;
  }

  static getPaymentMethodHash() {
    return {
      "irembopay-momo": "MTN",
      "irembopay-momo-airtel": "AIRTEL",
      "irembopay-bank-transfer": "BPR"
    };
  }

  static getShortDescText(paymentMethod) {
    var descSplit = context["dialThis"].split("MTN");
    return `${descSplit[0]} ${HtmlBuilder.getPaymentMethodHash()[paymentMethod]
      } ${descSplit[1]}`;
  }

  static getPayGuideText(paymentMethod) {
    if (paymentMethod === "irembopay-momo-airtel") {
      return context["payGuide"].replace("Mobile Money", "Airtel Money");
    } else {
      return context["payGuide"];
    }
  }

  static getMomoInputLabelText(paymentMethod) {
    var suffixHash = {
      "irembopay-momo": "MoMo",
      "irembopay-momo-airtel": "Money",
    };

    var textSplit = context["enterNumber"].split("MTN");

    return `${textSplit[0]} ${HtmlBuilder.getPaymentMethodHash()[paymentMethod]
      } ${suffixHash[paymentMethod]} ${textSplit[1]}`;
  }

  static getProviderLogo(provider) {
    var PAYMENT_METHOD_KEY_LOGO = {
      "irembopay-momo": Actions.getHostingUrl() + "/assets/payment/img/MTN.png",
      "irembopay-momo-airtel":
        Actions.getHostingUrl() + "/assets/payment/img/Airtel.png",
      "kigali":
        Actions.getHostingUrl() + "/assets/payment/img/bkLogo.PNG",
      "Bank of Kigali branches and agents":
        Actions.getHostingUrl() + "/assets/payment/img/bkLogo.PNG",
      "Equity bank mobile app and internet banking":
        Actions.getHostingUrl() + "/assets/payment/img/equityBankLogo.PNG",
      "Equity bank branches and agents":
        Actions.getHostingUrl() + "/assets/payment/img/equityBankLogo.PNG",
      "I&M":
        Actions.getHostingUrl() + "/assets/payment/img/IMbankLogo.PNG",
      "I&M mobile app and internet banking":
        Actions.getHostingUrl() + "/assets/payment/img/IMbankLogo.PNG",
      "Ecobank branches, agents and USSD app":
        "https://res.cloudinary.com/irembopay/image/upload/v1730447860/Ecobank_Logo_j9fcpm.png",
      
      "Ecobank mobile app and internet banking":
        "https://res.cloudinary.com/irembopay/image/upload/v1730447860/Ecobank_Logo_j9fcpm.png",

      // "NCBA USSD app":
      //   "https://res.cloudinary.com/irembopay/image/upload/v1733814471/NCBA_LOGO_black_vajkrh.png",
      
      // "NCBA mobile app and internet banking":
      //   "https://res.cloudinary.com/irembopay/image/upload/v1733814471/NCBA_LOGO_black_vajkrh.png",
    };

    return PAYMENT_METHOD_KEY_LOGO[provider];
  }

  static getBankProviderLogo(provider) {
    if (provider.includes("kigali")) {
      return "https://res.cloudinary.com/irembopay/image/upload/v1729062752/bkLogo_b9hvuk_fxsllg.png"
    } else if (provider.includes("i&m") || provider.includes("iclick")) {
      return "https://res.cloudinary.com/irembopay/image/upload/v1729062756/IandMlogo_tzur3p_vm98lv.png"
    } else if (provider.includes("equity")) {
      return "https://res.cloudinary.com/irembopay/image/upload/v1729062753/equityBankLogo_fibfue_pkvz10.png"
    } else if (provider.includes("ecobank")) {
      return "https://res.cloudinary.com/irembopay/image/upload/v1730447860/Ecobank_Logo_j9fcpm.png"
    } 
    // else if (provider.includes("ncba")) {
    //   return "https://res.cloudinary.com/irembopay/image/upload/v1733814471/NCBA_LOGO_black_vajkrh.png"
    // } 
    else {
      return ""
    }
  }

  static getUssdCode(provider) {
    var PAYMENT_METHOD_KEY_CODE = {
      "irembopay-momo": "*182*3*7* ",
      "irembopay-momo-airtel": "*182*4*5*1* ",
    };
    return PAYMENT_METHOD_KEY_CODE[provider];
  }

  static getPlacePhoneHolders(provider) {
    var PAYMENT_METHOD_PLACEHOLDERS = {
      "irembopay-momo": "ex: 078/9xxxxxxx",
      "irembopay-momo-airtel": "ex: 072xxxxxxx",
    };
    return PAYMENT_METHOD_PLACEHOLDERS[provider];
  }

  static initiateAction() {
    var phoneNumber = document.getElementById("irembopay-phone").value;
    if (!Actions.validPhoneNumber(phoneNumber)) {
      Actions.setErrorText(context["invalidPhoneNumber"]);
      return;
    } else {
      Actions.setErrorText("");
    }

    Actions.toggleMomoInputField({ disable: true });

    var payload = {
      phoneNumber,
      provider: HtmlBuilder.getPaymentMethodHash()[PAYMENT_METHOD]
    }
    Payment.requestPush(payload, context)
  }

  static createButton(id, label, onClick) {
    var customButton = document.createElement("button");
    customButton.type = "submit";
    customButton.id = id;
    customButton.value = "Submit";
    customButton.classList.add("irembopay-button", "irembopay-primary-cta");
    customButton.onclick = onClick;
    var customButtonText = customButton.appendChild(
      document.createElement("span")
    );
    customButtonText.classList.add("irembopay-btnText");
    customButtonText.appendChild(document.createTextNode(label));

    return customButton;
  }

  static createMomoRefreshSection(invoiceNumber) {
    var momoBottomSection = document.getElementById(
      "irembopay-momoBottomSection"
    );
    var button = HtmlBuilder.createButton(
      "irembopay-momoBtnRefresh",
      context["refresh"],
      function () {
        Actions.refreshInoviceStatus(invoiceNumber);
      }
    );

    var payWithAnotherNumber = document.createElement("div");
    payWithAnotherNumber.id = "irembopay-payWithAnotherNumber";
    payWithAnotherNumber.innerHTML = context["payWithAnotherNumber"];
    payWithAnotherNumber.classList.add("irembopay-text-bold");
    payWithAnotherNumber.onclick = function () {
      HtmlBuilder.togglePaymentNotReceived({ show: false });
      Actions.toggleMomoInputField({ disable: false });
      Actions.toggleButtonClicked("irembopay-payBtn");
    };

    var refreshSection = document.createElement("div");
    refreshSection.id = "irembopay-momoRefreshSection";
    refreshSection.appendChild(button);
    refreshSection.appendChild(payWithAnotherNumber);

    refreshSection.style.display = "none";

    momoBottomSection.appendChild(refreshSection);
  }

  static removeNotificationPopUp(modalObject) {
    var notificationPopUp = document.getElementById(
      "irembopay-notificationPopUp"
    );
    modalObject.removeChild(notificationPopUp);
  }

  static showNotificationPopUp(modalObject, message, data) {
    var notificationPopUp = modalObject.appendChild(
      document.createElement("div")
    );
    notificationPopUp.id = "irembopay-notificationPopUp";
    notificationPopUp.classList.add("irembopay-alertBox");

    if (data.showSpinner && data.showSpinner == true) {
      notificationPopUp.classList.add("irembopay-flex-row");
      var spinner = document.createElement("div");
      spinner.innerHTML = this.getSpinner();
      notificationPopUp.appendChild(spinner);

      var iremboPayLogo = document.createElement("img");
      iremboPayLogo.src =
        Actions.getHostingUrl() + "/assets/payment/img/irembopay_logo.svg";
      iremboPayLogo.classList.add("irembopay-logo-md");

      notificationPopUp.appendChild(iremboPayLogo);
    } else {
      var logo = document.createElement("img");
      logo.src =
        Actions.getHostingUrl() + "/assets/payment/img/irembopay_logo.svg";
      logo.classList.add("irembopay-logo-md");

      notificationPopUp.appendChild(logo);

      var text = document.createElement("p");
      text.innerText = message;
      text.style = "margin-top:30px; color:#E0115F;";
      notificationPopUp.appendChild(text);
      notificationPopUp.appendChild(text);
      notificationPopUp.appendChild(text);

      if (data.showClosedButton && data.showClosedButton == true) {
        var closeButton = document.createElement("button");
        closeButton.innerText = "Close";
        closeButton.classList.add("irembopay-button");
        notificationPopUp.appendChild(closeButton);

        closeButton.onclick = function () {
          notificationPopUp.style.display = "none";
          modalObject.style.display = "none";
          Actions.removeCustomAlert();
        };
      }
    }
  }

  static createSuccessModal(modalObject, data) {
    var successModalId = "irembopay-successBox";
    if (document.getElementById(successModalId)) {
      return;
    }
    var doc = document;
    var successObj = modalObject.appendChild(doc.createElement("div"));
    successObj.id = successModalId;
    successObj.classList.add("irembopay-alertBox");
    successObj.style.display = "none";

    var modalCloseButton = successObj.appendChild(doc.createElement("div"));
    modalCloseButton.id = "irembopay-modalCloseButton";
    modalCloseButton.classList.add("irembopay-success-modalCloseBtn")
    modalCloseButton.innerHTML = "X";
    modalCloseButton.onclick = Actions.removeCustomAlert;

    var logoSection = successObj.appendChild(doc.createElement("div"));
    logoSection.classList.add("irembopay-logoSectionContainer");

    var img = document.createElement("img");
    img.src = "https://res.cloudinary.com/irembopay/image/upload/v1729062754/Featured_icon_sbe3w3_vcckva.jpg"
    img.alt = "Logo";
    img.style.width = "20%";
    img.style.height = "20%";
    img.style.marginBottom = "10px";
    logoSection.appendChild(img);
    var paymentCompletionTextContainer = logoSection.appendChild(
      doc.createElement("div")
    );
    paymentCompletionTextContainer.classList.add(
      "irembopay-paymentCompletionContainer"
    );
    var paymentSuccessMessage = paymentCompletionTextContainer.appendChild(
      doc.createElement("div")
    );
    paymentSuccessMessage.classList.add(
      "irembopay-paymentSuccessMessage",
      "irembopay-text-bold"
    );
    paymentSuccessMessage.appendChild(
      doc.createTextNode(context["paymentSuccessTitle"])
    );
    var paymentDescription = paymentCompletionTextContainer.appendChild(
      doc.createElement("p")
    );
    paymentDescription.classList.add('irembopay-text-small')
    paymentDescription.appendChild(doc.createTextNode(context["paymentSuccessMessage"]))
    // //Dashed line
    // var hr = successObj.appendChild(doc.createElement("hr"));
    // hr.classList.add("irembopay-hr");

    // //application number section
    // var appNumberSection = successObj.appendChild(doc.createElement("div"));
    // appNumberSection.classList.add("irembopay-appNumberSection");

    // var appNumberContainer = appNumberSection.appendChild(
    //   doc.createElement("div")
    // );
    // appNumberContainer.classList.add(
    //   "irembopay-appNumberContainer",
    //   "irembopay-text-bold"
    // );

    // var appNumberLabel = appNumberContainer.appendChild(
    //   doc.createElement("div")
    // );
    // appNumberLabel.appendChild(doc.createTextNode("Invoice Number:"));
    // appNumberLabel.classList.add("irembopay-appNumberLabel");

    // var appNumber = appNumberContainer.appendChild(doc.createElement("div"));
    // appNumber.appendChild(doc.createTextNode(data.invoiceNumber));
    // appNumber.classList.add("irembopay-appNumber");

    // var appNumberSectionDescr = appNumberSection.appendChild(
    //   doc.createElement("div")
    // );
    // appNumberSectionDescr.classList.add("irembopay-appNumberSectionDescr");
    // appNumberSectionDescr.appendChild(
    //   doc.createTextNode("Use this number to track your application")
    // );

    // //Dashed line
    // hr = successObj.appendChild(doc.createElement("hr"));
    // hr.classList.add("irembopay-hr");
  }

  static greenCircleLogo() {
    return `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="64px" height="71px" viewBox="0 0 64 71" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Group 4</title>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stop-color="#87E747" offset="0%"></stop>
          <stop stop-color="#50CA22" offset="100%"></stop>
        </linearGradient>
        <path d="M19.0780657,8.87075838 L10.3942261,18.0530215 C10.2363381,18.2199717 10.0784501,18.3034468 9.84161814,18.3034468 C9.60478615,18.3034468 9.44689816,18.2199717 9.28901017,18.0530215 L5.34181037,13.8792655 C5.02603439,13.545365 5.02603439,13.0445143 5.34181037,12.7106139 C5.65758636,12.3767134 6.13125033,12.3767134 6.44702632,12.7106139 L9.84161814,16.300044 L17.9728497,7.70210671 C18.2886257,7.36820624 18.7622897,7.36820624 19.0780657,7.70210671 C19.3938416,8.03600719 19.3938416,8.5368579 19.0780657,8.87075838 Z" id="path-2"></path>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Payment-component" transform="translate(-1205.000000, -272.000000)">
          <g id="Group-5" transform="translate(1179.000000, 240.000000)">
            <g id="Group-4" transform="translate(26.000000, 32.000000)">
              <g id="Group-3" transform="translate(0.000000, 9.450913)" fill="#19BA5E">
                <path d="M51.3214332,11.2128089 C47.2484444,5.82461782 41.6731237,1.98070577 35.1964039,0.117499495 L34.9627078,0.0503569264 L34.7123192,0.0503569264 C34.6455489,0.0503569264 34.5453934,0.0335712843 34.4285454,0.0335712843 C34.244927,0.0167856421 34.0279235,-5.57442367e-14 33.7775349,-5.57442367e-14 C31.4739593,-5.57442367e-14 29.4207723,1.56106472 28.7697618,3.79355512 L28.7697618,3.81034076 C28.0185959,6.56318607 29.5710055,9.39995959 32.3252807,10.2560273 L32.3753584,10.272813 C41.1055761,12.757088 47.1983667,20.8645532 47.2150592,30.0127281 C47.2150592,41.3094653 38.0842197,50.5079972 26.8501155,50.5079972 C21.3749503,50.5079972 16.2169441,48.3426493 12.3609588,44.3980234 C11.3760968,43.4076706 10.0740758,42.87053 8.67189932,42.87053 C7.28641544,42.87053 5.98439445,43.4076706 4.99953241,44.3812378 C3.9979778,45.354805 3.43043019,46.6640851 3.43043019,48.0740791 C3.43043019,49.484073 3.98128523,50.7933531 4.98283984,51.7669203 C10.7918566,57.6754664 18.5539048,60.9150953 26.8501155,60.9150953 C43.8264661,60.9150953 57.6312272,47.0165836 57.6312272,29.9455856 C57.597842,23.1138292 55.4278071,16.651357 51.3214332,11.2128089 Z" id="_x32_"></path>
                <path d="M9.64374233,19.0256113 C12.458213,14.7507626 16.6546394,11.6586783 21.4914842,10.3407408 C22.8228805,9.96901479 23.9520394,9.10728639 24.6598704,7.90762529 C25.3845544,6.67417092 25.5699387,5.25485356 25.1991701,3.90312274 L25.182317,3.8355362 C24.4407798,1.53759381 22.4015526,2.78721184e-15 20.0758223,2.78721184e-15 C19.6713475,2.78721184e-15 19.2500195,0.0506899057 18.7612791,0.185862988 L18.7275729,0.202759623 C11.3627604,2.19656258 5.00913491,6.86003391 0.863267908,13.2976519 C0.088024485,14.4635198 -0.181625401,15.8659405 0.121730721,17.2683612 C0.408233725,18.620092 1.21718338,19.8028565 2.38004852,20.5969984 C3.22270441,21.1545873 4.2507446,21.4587268 5.26193168,21.4587268 C7.03150906,21.4249335 8.71682085,20.5125152 9.64374233,19.0256113 Z" id="_x31_"></path>
              </g>
              <g id="Group-2" transform="translate(39.093407, 0.000000)">
                <ellipse id="Oval" fill="url(#linearGradient-1)" fill-rule="nonzero" cx="12.3029604" cy="12.2158711" rx="12.3029604" ry="12.2158711"></ellipse>
                <mask id="mask-3" fill="white">
                  <use xlink:href="#path-2"></use>
                </mask>
                <use id="Mask" fill="#FFFFFF" fill-rule="nonzero" xlink:href="#path-2"></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>`;
  }

  static infoIcon() {
    return `<svg width="16" height="16" viewBox="-2 -2 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.59996 0.333334C4.39996 0.333334 4.19996 0.400001 4.13329 0.466667L0.199959 4.4C0.0666256 4.53333 -4.19114e-05 4.66667 -4.18939e-05 4.86667L-4.14101e-05 10.4C-4.13927e-05 10.6 0.0666252 10.8 0.133292 10.8667L4.06663 14.8C4.19996 14.9333 4.33329 15 4.53329 15L10.0666 15C10.2666 15 10.4666 14.9333 10.5333 14.8L14.4666 10.8667C14.6 10.7333 14.6666 10.6 14.6666 10.4L14.6666 4.93333C14.6666 4.73333 14.6 4.53333 14.4666 4.46667L10.5333 0.533334C10.4 0.400001 10.2666 0.333333 10.0666 0.333333L4.59996 0.333334ZM4.79996 1.66667L9.79996 1.66667L13.3333 5.13333L13.3333 10.1333L9.79996 13.6667L4.86663 13.6667L1.26663 10.1333L1.26663 5.2L4.79996 1.66667ZM6.66663 7.66667L6.66663 10.3333C6.66663 10.7333 6.93329 11 7.33329 11C7.73329 11 7.99996 10.7333 7.99996 10.3333L7.99996 7.66667C7.99996 7.26667 7.73329 7 7.33329 7C6.93329 7 6.66663 7.26667 6.66663 7.66667ZM6.73329 4.73333C6.73329 4.66667 6.79996 4.6 6.86662 4.53333C6.99996 4.4 7.13329 4.33333 7.39996 4.33333C7.59996 4.33333 7.73329 4.4 7.86663 4.53333C7.99996 4.66667 8.06663 4.8 8.06663 5C8.06663 5.07811 8.04375 5.13333 8.0248 5.17909C8.0114 5.21144 7.99996 5.23905 7.99996 5.26667C7.99996 5.33333 7.93329 5.4 7.86663 5.46667C7.66663 5.66667 7.39996 5.73333 7.13329 5.6C7.09996 5.6 7.08329 5.58333 7.06663 5.56667C7.04996 5.55 7.03329 5.53333 6.99996 5.53333C6.93329 5.53333 6.86662 5.46667 6.86662 5.46667C6.83329 5.43333 6.81662 5.4 6.79996 5.36667C6.78329 5.33333 6.76663 5.3 6.73329 5.26667C6.66663 5.2 6.66663 5.06667 6.66663 5C6.66663 4.96667 6.68329 4.91667 6.69996 4.86667C6.71663 4.81667 6.73329 4.76667 6.73329 4.73333Z" fill="black"/>
    <mask id="mask0_898_1861" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.59996 0.333334C4.39996 0.333334 4.19996 0.400001 4.13329 0.466667L0.199959 4.4C0.0666256 4.53333 -4.19114e-05 4.66667 -4.18939e-05 4.86667L-4.14101e-05 10.4C-4.13927e-05 10.6 0.0666252 10.8 0.133292 10.8667L4.06663 14.8C4.19996 14.9333 4.33329 15 4.53329 15L10.0666 15C10.2666 15 10.4666 14.9333 10.5333 14.8L14.4666 10.8667C14.6 10.7333 14.6666 10.6 14.6666 10.4L14.6666 4.93333C14.6666 4.73333 14.6 4.53333 14.4666 4.46667L10.5333 0.533334C10.4 0.400001 10.2666 0.333333 10.0666 0.333333L4.59996 0.333334ZM4.79996 1.66667L9.79996 1.66667L13.3333 5.13333L13.3333 10.1333L9.79996 13.6667L4.86663 13.6667L1.26663 10.1333L1.26663 5.2L4.79996 1.66667ZM6.66663 7.66667L6.66663 10.3333C6.66663 10.7333 6.93329 11 7.33329 11C7.73329 11 7.99996 10.7333 7.99996 10.3333L7.99996 7.66667C7.99996 7.26667 7.73329 7 7.33329 7C6.93329 7 6.66663 7.26667 6.66663 7.66667ZM6.73329 4.73333C6.73329 4.66667 6.79996 4.6 6.86662 4.53333C6.99996 4.4 7.13329 4.33333 7.39996 4.33333C7.59996 4.33333 7.73329 4.4 7.86663 4.53333C7.99996 4.66667 8.06663 4.8 8.06663 5C8.06663 5.07811 8.04375 5.13333 8.0248 5.17909C8.0114 5.21144 7.99996 5.23905 7.99996 5.26667C7.99996 5.33333 7.93329 5.4 7.86663 5.46667C7.66663 5.66667 7.39996 5.73333 7.13329 5.6C7.09996 5.6 7.08329 5.58333 7.06663 5.56667C7.04996 5.55 7.03329 5.53333 6.99996 5.53333C6.93329 5.53333 6.86662 5.46667 6.86662 5.46667C6.83329 5.43333 6.81662 5.4 6.79996 5.36667C6.78329 5.33333 6.76663 5.3 6.73329 5.26667C6.66663 5.2 6.66663 5.06667 6.66663 5C6.66663 4.96667 6.68329 4.91667 6.69996 4.86667C6.71663 4.81667 6.73329 4.76667 6.73329 4.73333Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_898_1861)">
    <rect x="15.3333" y="15.6667" width="16" height="16" transform="rotate(180 15.3333 15.6667)" fill="#0063CF"/>
    </g>
    </svg>
    `;
  }


  static getPaymentOptionsMobile(currency, allowedPaymentProviders) {
    var output = ``;

    var offlineOption = allowedPaymentProviders.includes('BK') ? `<option value="irembopay-offline">${context['cashAgents']}</option>` : '';
    var bankOption = allowedPaymentProviders.includes('BK') ? `<option value="irembopay-bank">${context['bankAccounts']}</option>` : '';
    var airtelOption = allowedPaymentProviders.includes('AIRTEL_MONEY') ? `<option value="irembopay-momo-airtel">Airtel Money</option>` : '';
    var mtnOption = allowedPaymentProviders.includes('MTN_MOMO') ? `<option value="irembopay-momo">MTN Mobile Money</option>` : '';
    var cardOption = allowedPaymentProviders.includes('ONLINE_CARD') ? `<option value="irembopay-card">${context['visaCard']}</option>` : '';
    var bankTransferOption = allowedPaymentProviders.includes('BPR') ? `<option value="irembopay-bank-transfer">${context['bankTransfer']}</option>` : '';

    if (Actions.currencyIs(currency, "rwf")) {
      output = `
      <select class="form-control" id="irembopay-payment-options">
        ${mtnOption}
        ${airtelOption}
        ${cardOption}
        ${offlineOption}
        ${bankOption}
        ${bankTransferOption}
      </select>
      `;
    } else {
      output = `
      <select class="form-control" id="irembopay-payment-options">
        ${cardOption}
        ${offlineOption}
        ${bankOption}
        ${bankTransferOption}
      </select>
      `;
    }

    return output;
  }

  static getPaymentOptionsDesktop(currency, allowedPaymentProviders) {
    var offlineOption = allowedPaymentProviders.includes('BK') ?
      `
  <label class="irembopay-container irembopay-label">
    <span id="irembopay-offline_radio" class="irembopay-radio-text">${context['cashAgents']}</span>
    <input type="radio" value="irembopay-offline" name="irembopay-payment_option">
    <span class="irembopay-checkmark"></span>
  </label>
  ` : '';

    var bankOption = allowedPaymentProviders.includes('BK') ?
      `
  <label class="irembopay-container irembopay-label">
    <span id="irembopay-bank_radio" class="irembopay-radio-text">${context['bankAccounts']}</span>
    <input type="radio" value="irembopay-bank" name="irembopay-payment_option">
    <span class="irembopay-checkmark"></span>
  </label>
  ` : '';

    var bankTransferOption = allowedPaymentProviders.includes('BPR') ?
      `
    <label class="irembopay-container irembopay-label">
      <span id="irembopay-bank-transfer_radio" class="irembopay-radio-text">${context['bankTransfer']}</span>
      <input type="radio" value="irembopay-bank-transfer" name="irembopay-payment_option">
      <span class="irembopay-checkmark"></span>
    </label>
    ` : '';

    var mtnOption = allowedPaymentProviders.includes('MTN_MOMO') ?
      `<label class="irembopay-container irembopay-label">
    <span id="irembopay-momo_radio" class="irembopay-text-teal-deep">MTN Mobile Money</span>
    <input type="radio" value="irembopay-momo" checked="checked" name="irembopay-payment_option">
    <span class="irembopay-checkmark"></span>
  </label>
` : '';

    var airtelOption = allowedPaymentProviders.includes('AIRTEL_MONEY') ?
      `
  <label class="irembopay-container irembopay-label">
    <span id="irembopay-momo-airtel_radio" class="irembopay-radio-text">Airtel Money</span>
    <input type="radio" value="irembopay-momo-airtel" name="irembopay-payment_option">
    <span class="irembopay-checkmark"></span>
  </label>
` : '';

    var cardOption = allowedPaymentProviders.includes('ONLINE_CARD') ?
      `
  <label class="irembopay-container irembopay-label">
    <span id="irembopay-card_radio" class="irembopay-radio-text">${context['visaCard']}</span>
    <input type="radio" value="irembopay-card" name="irembopay-payment_option">
    <span class="irembopay-checkmark"></span>
  </label>
` : '';

    var output = ``;

    if (Actions.currencyIs(currency, "rwf")) {
      output += mtnOption;
      if (airtelOption) output += airtelOption;
      output += cardOption;
    } else {
      output = cardOption;
    }

    if (offlineOption) output += offlineOption;
    if (bankOption) output += bankOption;
    if (bankTransferOption) output += bankTransferOption;
    return output;
  }

  static showInitialPaymentOption(currency, allowedPaymentProviders, isMPGS) {
    window.CARD_SELECTED = false;
    const isRwf = Actions.currencyIs(currency, "rwf");
    const hasMomo = allowedPaymentProviders.includes('MTN_MOMO') || allowedPaymentProviders.includes('AIRTEL_MONEY');
    const hasOnlineCard = allowedPaymentProviders.includes('ONLINE_CARD');
    const hasBanks = allowedPaymentProviders.includes('BK') || allowedPaymentProviders.includes('I_M');

    if (isRwf) {
      if (hasMomo) {
        this.showMomoForm(allowedPaymentProviders);
      } else if (hasOnlineCard) {
        window.CARD_SELECTED = true;
        Actions.changePayVia("irembopay-card", null, null, null, isMPGS);
      } else {
        Actions.changePayVia("irembopay-offline");
      }
    } else {
      if (hasOnlineCard) {
        window.CARD_SELECTED = true;
        Actions.changePayVia("irembopay-card", null, null, null, isMPGS);
      } else if (hasBanks) {
        Actions.changePayVia("irembopay-offline");
      }
    }
  }

  static showMomoForm(allowedPaymentProviders) {
    const momoContainer = document.getElementById("irembopay-momoFormContainer");
    momoContainer.classList.remove('irembopay-hide');
    momoContainer.classList.add('irembopay-show');
    Payment.SELECTED_PAYMENT_METHOD = "MTN Mobile Money";
    if (!allowedPaymentProviders.includes('MTN_MOMO') && allowedPaymentProviders.includes('AIRTEL_MONEY')) {
      Payment.SELECTED_PAYMENT_METHOD = "Airtel Money";
      this.setValueFromPaymentOption("irembopay-momo-airtel");
    }
  }

  static showCardErrors({ show }) {
    var cardError = document.getElementById("irembopay-cardError");
    if (show) {
      cardError.style.display = "flex";
      Payment.trackEvent("Payment Failure", {
        "invoiceNumber": Payment.IREMBOPAY_VERIFICATION_DATA.invoiceNumber,
        "currency": Payment.IREMBOPAY_VERIFICATION_DATA.currency,
        "merchant": Payment.IREMBOPAY_VERIFICATION_DATA?.appAccount?.name,
        "paymentMethod": Payment.SELECTED_PAYMENT_METHOD,
        "errorMessage": cardError.innerText || cardError.textContent
      })
    } else {
      cardError.style.display = "none";
    }
  }

  static initiatePhoneValidation(e) {
    Actions.clearPhoneNumberError()
  }

  static isCardFormValid() {
    var isCardNumberValid = HtmlBuilder.validate(
      document.getElementById("irembopay-cardNumber"),
      PaymentVal.fns.validateCardNumber
    );
    var isCardExpiryValid = HtmlBuilder.validate(
      document.getElementById("irembopay-cardDate"),
      PaymentVal.fns.validateCardExpiry
    );
    var isCardCvvValid = HtmlBuilder.validate(
      document.getElementById("irembopay-cardCvv"),
      PaymentVal.fns.validateCardCVC
    );
    var isCardNameValid = HtmlBuilder.validate(
      document.getElementById("irembopay-cardNameInput"),
      HtmlBuilder.validateCardHolderName
    );
    return (
      isCardNumberValid &&
      isCardExpiryValid &&
      isCardCvvValid &&
      isCardNameValid
    );
  }

  static validate(element, fn) {
    if (!fn(element.value)) {
      element.classList.add("irembopay-invalid-card-details");
      return false;
    } else {
      element.classList.remove("irembopay-invalid-card-details");
      return true;
    }
  }

  static validateCardHolderName(value) {
    return value.length > 0;
  }

  static getSpinner() {
    return `
    <div id="irembopay-loading-spinner" >
      <div class="irembopay-spinner-block" id="irembopay-rotateG_01"></div>
      <div class="irembopay-spinner-block" id="irembopay-rotateG_02"></div>
      <div class="irembopay-spinner-block" id="irembopay-rotateG_03"></div>
      <div class="irembopay-spinner-block" id="irembopay-rotateG_04"></div>
      <div class="irembopay-spinner-block" id="irembopay-rotateG_05"></div>
      <div class="irembopay-spinner-block" id="irembopay-rotateG_06"></div>
      <div class="irembopay-spinner-block" id="irembopay-rotateG_07"></div>
      <div class="irembopay-spinner-block" id="irembopay-rotateG_08"></div>
    </div>`;
  }

  static createCardLoadingSection() {
    var alertBox = document.getElementById("irembopay-alertBox");
    var cardSpinnerContainer = alertBox.appendChild(document.createElement("div"));
    cardSpinnerContainer.id = "irembopay-card-spinner-container";
    cardSpinnerContainer.classList.add("irembopay-card-spinner-container");
    var cardSpinner = cardSpinnerContainer.appendChild(document.createElement("div"));
    cardSpinner.id = "irembopay-card-spinner"
    cardSpinner.innerHTML = this.getSpinner();
  }
}
