export default class HttpRequest {
  static post(url, body, meta = {}) {
    return new Promise((resolve, reject) => {
      var http = new XMLHttpRequest();
      http.open("POST", url, true);

      http.setRequestHeader("Content-Type", "application/json");
      http.setRequestHeader("charset", "utf-8");
      http.setRequestHeader("Accept-Language", meta.locale || 'en')
      http.send(JSON.stringify(body));

      http.onreadystatechange = function () {
        if (http.readyState == 4) {
          if (http.status == 200 || http.status == 201) {
            var obj = JSON.parse(this.responseText);
            return resolve(obj);
          } else {
						return reject(http.responseText)
          }
        }
      };
    });
  }

  static get(url) {
    return new Promise((resolve, reject) => {
      var http = new XMLHttpRequest();
      http.open("GET", url, true);

      http.setRequestHeader("Content-Type", "application/json");
      http.setRequestHeader("charset", "utf-8");

      http.send();

      http.onreadystatechange = function () {
        if (http.readyState == 4) {
          if (http.status == 200 || http.status == 201) {
            var obj = JSON.parse(this.responseText);
            return resolve(obj);
          } else {
            return reject(this.responseText);
          }
        }
      };
    });
  }

  static put(url, body, meta = {}) {
    return new Promise((resolve, reject) => {
      var http = new XMLHttpRequest();
      http.open("PUT", url, true);

      http.setRequestHeader("Content-Type", "application/json");
      http.setRequestHeader("charset", "utf-8");
      http.setRequestHeader("Accept-Language", meta.locale || 'en')
      http.send(JSON.stringify(body));

      http.onreadystatechange = function () {
        if (http.readyState == 4) {
          if (http.status == 200 || http.status == 201) {
            var obj = JSON.parse(this.responseText);
            return resolve(obj);
          } else {
						return reject(http.responseText)
          }
        }
      };
    });
  }
}
