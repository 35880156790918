import HttpRequest from "./http-request";
import HtmlBuilder from "./html-builder";
import Actions from "./actions";
import mixpanel from "mixpanel-browser";

var retryCard = false;

class IremboPayErrors {
  static badLocale = "BAD_LOCALE"
  static invoiceMissing = "INVOICE_MISSING"
  static invalidRequest = "INVALID_REQUEST"


  static getError(errorObj) {
    var errors = JSON.parse(errorObj)['errors']
    return {
      code: errors[0]['code'],
      details: errors[0]['detail']
    }
  }

  static isBadLocale(locale) {
    return !Object.values(IremboPay.locale).includes(locale)
  }

}

export default class Payment {
  static IREMBOPAY_PAYMENT_DATA = {};
  static IREMBOPAY_VERIFICATION_DATA = {};
  static SELECTED_PAYMENT_METHOD = "";

  static SetRetryCard(value) {
    retryCard = value;
  }

  static onCardSubmission() {
    HtmlBuilder.showCardErrors({ show: false });
    if (HtmlBuilder.isCardFormValid()) {
      Actions.disableButtonToggle("irembopay-cardPayBtn", true);
      if (retryCard) {
        HtmlBuilder.showCardErrors({ show: false });
        //reverify the invoice
        Payment.reVerifyInvoice(retryCard);
      } else {
        Actions.onCardSubmit(Payment.IREMBOPAY_PAYMENT_DATA, Payment.IREMBOPAY_VERIFICATION_DATA, retryCard)
      }
    } else {
      alert("Invalid payment credentials");
    }
  }

  static onCardSubmissionMPGS() {
    HtmlBuilder.showCardErrors({ show: false });
    Actions.disableButtonToggle("irembopay-cardPayBtn", true);
    try {
      Actions.toggleCardDisplay({ disable: true });
      Actions.initiateTransactionStatusCheck(Payment.IREMBOPAY_PAYMENT_DATA, { isCard: true });
      PaymentSession.validate('card', function (result) {
        if (result.card.isValid) {
          console.log('All hosted fields of "card" paymentType are valid');
        } else {
          if(result.card.nameOnCard.isValid) {
            console.log("card name is valid")
          } else {
            console.log("card name is not valid")
          }

          if(result.card.securityCode.isValid) {
            console.log("security code is valid")
          } else {
            console.log("security code is not valid")
          }
        }
      });

      PaymentSession.updateSessionFromForm('card');
    } catch (error) {
      Actions.toggleCardDisplay({ disable: false });
      alert(error);
    }
    Actions.disableButtonToggle("irembopay-cardPayBtn", false);
  }

  static requestPush(payload, context) {
    var data = {
      paymentProvider: payload.provider,
      transactionReference: Payment.IREMBOPAY_PAYMENT_DATA.transactionId,
      invoiceNumber: Payment.IREMBOPAY_PAYMENT_DATA.invoiceNumber,
      accountIdentifier: payload.phoneNumber
    };
    var url = `${Actions.getApiBaseUrl()}/payments/transactions/initiate`;
    var currentBtnText = Actions.getButtonText("irembopay-payBtn");
    Actions.toggleButtonClicked("irembopay-payBtn");
    Actions.setButtonLoading("irembopay-payBtn", true);
    HttpRequest.post(url, data)
      .then((res) => {
        this.trackEvent('Initiate Invoice Payment', {
          invoiceNumber: Payment.IREMBOPAY_PAYMENT_DATA.invoiceNumber,
          currency: Payment.IREMBOPAY_PAYMENT_DATA.currency,
          merchant: Payment.IREMBOPAY_PAYMENT_DATA?.appAccount?.name,
          paymentMethod: payload.provider
        });
        const responseMessage = res['message'].toString().toUpperCase();
        if (responseMessage.includes("PENDING")) {
          HtmlBuilder.togglePaymentNotReceived({ show: true });
        }
        else {
          Payment.IREMBOPAY_PAYMENT_DATA['paymentReference'] = res['data']['referenceId']
          Actions.initiateTransactionStatusCheck(Payment.IREMBOPAY_PAYMENT_DATA, {
            buttonId: "irembopay-payBtn",
            buttonText: currentBtnText,
          });
        }
      })
      .catch((err) => {
        Actions.toggleButtonClicked("irembopay-payBtn");
        Actions.setButtonLoading("irembopay-payBtn", false);
        Actions.toggleMomoInputField({ disable: false })
        const errorMessage = Actions.getErrorMessage(err, context);
        if (errorMessage.toUpperCase().includes('PAID')) {
          Actions.executeAndCatchError(() => {
            HtmlBuilder.showSuccessBox();
            paymentData.callback(null, { message: "Payment successful" });
          })
        }
        else {
          Actions.setErrorText(errorMessage);
          this.trackEvent('Initiate Invoice Payment Error', {
            invoiceNumber: Payment.IREMBOPAY_PAYMENT_DATA.invoiceNumber,
            currency: Payment.IREMBOPAY_PAYMENT_DATA.currency,
            merchant: Payment.IREMBOPAY_PAYMENT_DATA?.appAccount?.name,
            paymentMethod: payload.provider,
            errorMessage: errorMessage
          });
        }
      });
  }

  static verifyInvoice(data) {
    return new Promise((resolve, reject) => {
      var params = {
        "publicKey": data.publicKey,
        "invoiceNumber": data.invoiceNumber,
        "amount": data.amount,
        "paymentAccountIdentifier": data.paymentAccountIdentifier,
        "transactionId": data.transactionId,
        "customerContact": {
          "phone": data?.customerContact?.phone,
          "email": data?.customerContact?.email
        }
      };
      var url = `${Actions.getApiBaseUrl()}/invoices/verify`;

      HttpRequest.post(url, params, { locale: data?.locale }).then(res => {
        return resolve(res.data);
      }).catch(err => {
        return reject(err);
      })
    });
  }

  static configure3DS(res) {
    return new Promise((resolve, reject) => {
      const {
        merchantId,
        scriptUrl,
        sessionId,
        threeDsUrl
      } = res.mpgsProfileDto;

      const MPGS_SCRIPT_ID = 'mpgs-script';
      const MPGS_3DS_SCRIPT_ID = 'mpgs-3ds-script';

      console.log('Starting to configure 3DS with:', { merchantId, sessionId });

      const existingMainScript = document.getElementById(MPGS_SCRIPT_ID);
      if (existingMainScript) {
        console.log('Removing existing PaymentSession script');
        existingMainScript.remove();
      }

      const existing3DSScript = document.getElementById(MPGS_3DS_SCRIPT_ID);
      if (existing3DSScript) {
        console.log('Removing existing ThreeDS script');
        existing3DSScript.remove();
      }

      const scriptURL = scriptUrl.replace("{merchantId}", merchantId);

      // Load the first script (PaymentSession)
      const script = document.createElement('script');
      script.id = MPGS_SCRIPT_ID;
      script.src = scriptURL;
      script.onload = () => {
        console.log('PaymentSession script loaded successfully');
        loadThreeDSScript();
      };
      script.onerror = (error) => {
        console.error('Failed to load PaymentSession script:', error);
        reject('Failed to load PaymentSession script');
      };

      // Append the script element to the document's 'head'
      document.head.appendChild(script);

      // Function to load the ThreeDS script after the first script has loaded
      function loadThreeDSScript() {
        const threeDSscript = document.createElement('script');
        threeDSscript.src = threeDsUrl;
        threeDSscript.id = MPGS_3DS_SCRIPT_ID;
        threeDSscript.onload = () => {
          console.log('ThreeDS script loaded successfully');

          // Small delay to ensure script is initialized
          setTimeout(configureThreeDS, 500);
        };
        threeDSscript.onerror = (error) => {
          console.error('Failed to load ThreeDS script:', error);
          reject('Failed to load ThreeDS script');
        };

        document.head.appendChild(threeDSscript);
      }

      // Function to configure ThreeDS after scripts are loaded
      function configureThreeDS() {
        try {
          if (typeof ThreeDS === 'undefined') {
            console.error('ThreeDS is not defined even after script load');
            reject('ThreeDS is not defined');
            return;
          }

          if (typeof PaymentSession === 'undefined') {
            console.error('PaymentSession is not defined even after script load');
            reject('PaymentSession is not defined');
            return;
          }

          console.log('Configuring ThreeDS...');
          ThreeDS.configure({
            merchantId: merchantId,
            sessionId: sessionId,
            containerId: "3DSUI",
            callback: function() {
              if (ThreeDS.isConfigured()) {
                console.log("ThreeDS configuration completed successfully");
                resolve('ThreeDS configured successfully');
              } else {
                console.error("ThreeDS configuration did not complete properly");
                reject('ThreeDS configuration failed');
              }
            },
            configuration: {
              userLanguage: "en-US",
              wsVersion: 75
            }
          });
        } catch (error) {
          console.error('Error during ThreeDS configuration:', error);
          reject(`Error during ThreeDS configuration: ${error.message}`);
        }
      }
    });
  }

  static configurePaymentSession(res) {
    try {
      window.MPGS_INITIALIZED = false;
      const { amount,
        invoiceNumber,
        currency,
        header,
        transactionId,
        sessionId,
        processingUri,
        updateSessionUrl,
        authenticatePayerUrl, initiateAuthUrl } = res.mpgsProfileDto;

      setTimeout(() => {
      PaymentSession.configure({
        session: sessionId,
        fields: {
          // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
          card: {
            number: "#irembopay-cardNumberMPGS",
            securityCode: "#irembopay-cardCvvMPGS",
            expiryMonth: "#irembopay-cardMonthMPGS",
            expiryYear: "#irembopay-cardYearMPGS",
            nameOnCard: "#irembopay-cardNameInputMPGS",
          }
        },
        interaction: {
          displayControl: {
            nameOnCard: "MANDATORY",
            cardSecurityCode: "MANDATORY"
          }
        },
        //SPECIFY YOUR MITIGATION OPTION HERE
        frameEmbeddingMitigation: ["javascript"],
        callbacks: {
          initialized: function (response) {
            // SHOW DOM CONTENT ONLY WHEN THE SESSION TO MPGS HAS BENN SUCCESSFULLY INITIALIZES
            window.MPGS_INITIALIZED = true;
            const cardSpinner = document.getElementById("irembopay-card-spinner-container");

            if (cardSpinner) {
              cardSpinner.style.display = "none";
              if(window.CARD_SELECTED) {
                const cardContainer = document.getElementById("irembopay-paymentCardForm");
                cardContainer.classList.remove('irembopay-hide');
                cardContainer.classList.add("irembopay-show");
              }
            }
          },

          formSessionUpdate: function (response) {
            // HANDLE RESPONSE FOR UPDATE SESSION
            var cardError = document.getElementById("irembopay-cardError");
            if (response.status) {
              if (response.status === "ok") {

                if (response.sourceOfFunds.provided.card.securityCode == undefined) {
                  HtmlBuilder.showCardErrors({ show: true });
                  Actions.toggleCardDisplay({ disable: false });
                  cardError.innerHTML = "Invalid Card CVV";
                } else if (response.sourceOfFunds.provided.card.nameOnCard == undefined) {
                  HtmlBuilder.showCardErrors({ show: true });
                  Actions.toggleCardDisplay({ disable: false });
                  cardError.innerHTML = "Invalid Card Holder Name";
                } else {
                  // INITIALIZE AJAX REQUEST TO REQUEST PAYMENT BASED ON SESSION ID
                  var http = new XMLHttpRequest();
                  var url = updateSessionUrl + sessionId;
                  var params = '';

                  http.open('POST', url, true);

                  http.setRequestHeader("Access-Control-Allow-Origin", "*");
                  //Send the proper header information along with the request
                  http.setRequestHeader('Authorization', header);

                  http.onreadystatechange = function () {//Call a function when the state changes.
                    if (http.readyState === 4 && http.status === 200) { // WE COULD GET RESPONSE FROM THE SERVER
                      // var obj = JSON.parse(http.responseText);
                      // going for initiate authentication process
                      Payment.initiateAuth(currency, sessionId, initiateAuthUrl, header, processingUri, transactionId, authenticatePayerUrl, amount);
                    }
                  }
                  http.send(params);
                  }
              } else if ("fields_in_error" === response.status) {
                  HtmlBuilder.showCardErrors({ show: true });
                  Actions.toggleCardDisplay({ disable: false });
                if (response.errors.cardNumber) {
                  cardError.innerHTML = "Invalid Card number";
                }
                if (response.errors.expiryYear || response.errors.expiryMonth) {
                  cardError.innerHTML = "Invalid Expiry month/year";
                }

                if (response.errors.securityCode) {
                  cardError.innerHTML = "Invalid CVV";
                }
              } else if ("request_timeout" === response.status) {
                  cardError.innerHTML = "Session update failed with request timeout: " + response.errors.message;
                  HtmlBuilder.showCardErrors({ show: true });
                  Actions.toggleCardDisplay({ disable: false });
              } else if ("system_error" === response.status) {
                  cardError.innerHTML = "System error: " + response.errors.message;
                  HtmlBuilder.showCardErrors({ show: true });
                  Actions.toggleCardDisplay({ disable: false });
              }
            } else {
              cardError.innerHTML = "Session update failed: " + response;
              HtmlBuilder.showCardErrors({ show: true });
              Actions.toggleCardDisplay({ disable: false });
            }
          },
        },
        interaction: {
          displayControl: {
            formatCard: "EMBOSSED",
            invalidFieldCharacters: "REJECT"
          }
        },
        order: {
          amount: amount,
          currency: currency,
          id: invoiceNumber
        }
      });

      }, 2000);

    } catch (error) {
      console.log("Unknown error: ", error);
    }
  }

  static authenticatePayer(currency, sessionId, header, processingUri, transactionId, authenticatePayerUrl, amount) {
    // INITIALIZE AJAX REQUEST TO REQUEST PAYMENT BASED ON ORDER ID AND TRANSACTION ID
    var http = new XMLHttpRequest();
    var responseUrl = processingUri + sessionId + '/' + transactionId + '/' + amount + '/' + currency;
    var params = {
      "authentication": {
        "redirectResponseUrl": responseUrl
      },
      "apiOperation": "AUTHENTICATE_PAYER",
      "session": {
        "id": sessionId
      },
      "order": {
        "amount": amount,
        "currency": currency,
        "walletProvider": "MASTERPASS_ONLINE"
      },
      "device": {
        "browser": "MOZILLA",
        "browserDetails": {
          "3DSecureChallengeWindowSize": "FULL_SCREEN",
          "acceptHeaders": "application/json",
          "colorDepth": 24,
          "javaEnabled": true,
          "language": "en-US",
          "screenHeight": 640,
          "screenWidth": 480,
          "timeZone": 273
        },
        // "ipAddress": "127.0.0.1"
      }
    };

    http.open('POST', authenticatePayerUrl, true);

    //Send the proper header information along with the request
    http.setRequestHeader('Authorization', header);
    http.send(JSON.stringify(params));

    http.onreadystatechange = function () {
      if (http.readyState === 4 && http.status === 200) {
        var obj = JSON.parse(this.responseText);
        const recommendation = obj.response.gatewayRecommendation
        const htmlString = obj.authentication.redirect.html;
        const parser = new DOMParser();

        // Parse the HTML string as an HTML document
        const doc = parser.parseFromString(htmlString, 'text/html');

        const container = document.getElementById('authenticationDiv');
        container.innerHTML = '';

        switch (recommendation) {
          case "PROCEED":
             // Append the new div to your existing div container
            container.append(doc.body.firstElementChild);
            document.getElementById('authenticationParentDiv').style.display = "flex";
            eval(document.getElementById('authenticate-payer-script').text);
            break;
          case "DO_NOT_PROCEED":
            console.log("DO_NOT_PROCEED");
            break;
        }
      }
      ;
    };
  }

  static initiateAuth(currency, sessionId, initiateAuthUrl, header, processingUri, transactionId, authenticatePayerUrl, amount) {
    // INITIALIZE AJAX REQUEST TO REQUEST PAYMENT BASED ON ORDER ID AND TRANSACTION ID
    var http = new XMLHttpRequest();
    var params = {
      "authentication": {
        "acceptVersions": "3DS1,3DS2",
        "channel": "PAYER_BROWSER",
        "purpose": "PAYMENT_TRANSACTION"
      },
      "order": {
        "currency": currency
      },
      "sourceOfFunds": {
        "type": "CARD"
      },
      "apiOperation": "INITIATE_AUTHENTICATION",
      "session": {
        "id": sessionId
      }
    }
    http.open('PUT', initiateAuthUrl, true);

    //Send the proper header information along with the request
    http.setRequestHeader('Authorization', header);

    http.send(JSON.stringify(params));

    http.onreadystatechange = function () {
      if (http.readyState === 4 && http.status === 200) {
        var obj = JSON.parse(this.responseText);
        const recommendation = obj.response.gatewayRecommendation
        switch (recommendation) {
          case "PROCEED":
            Payment.authenticatePayer(currency, sessionId, header, processingUri, transactionId, authenticatePayerUrl, amount);
            break;
          case "DO_NOT_PROCEED":
            console.log("DO_NOT_PROCEED");
            break;
        }
      }
    };
  }

  static getTransactionStatus(paymentData) {
    return new Promise((resolve, reject) => {
      var url = `${Actions.getApiBaseUrl()}/invoices/${paymentData.invoiceNumber}/status`;
      HttpRequest.get(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static getBanks() {
    return new Promise((resolve, reject) => {
      var url = `${Actions.getApiBaseUrl()}/banks?limit=100`;
      HttpRequest.get(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static getCollectionAccount(currency) {
    return new Promise((resolve, reject) => {
      var url = `${Actions.getApiBaseUrl()}/banks/collection-accounts/${currency}`;
      HttpRequest.get(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static getBankTransferDetails(invoiceNumber) {
    return new Promise((resolve, reject) => {
      var url = `${Actions.getApiBaseUrl()}/payments/transactions/bank-transfer/${invoiceNumber}`;
      HttpRequest.get(url)
        .then((res) => {
          if (res.success) {
            return resolve(res);
          } else {
            return reject(res);
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static sendBankTransfer(payload, shouldUpdate) {
    if (shouldUpdate) {
      return this.updateBankTransfer(payload)
    } else {
      return this.initiateBankTransfer(payload)
    }
  }

  static initiateBankTransfer(payload) {
    var data = {
      invoiceNumber: Payment.IREMBOPAY_PAYMENT_DATA.invoiceNumber,
      paymentProvider: payload.provider,
      swiftCode: payload.swiftCode,
      accountNumber: payload.accountNumber,
      accountName: payload.accountName
    };
    var url = `${Actions.getApiBaseUrl()}/payments/transactions/bank-transfer`;
    return new Promise((resolve, reject) => {
      HttpRequest.post(url, data)
        .then((res) => {
          this.trackEvent('Initiate Invoice Payment', {
            invoiceNumber: Payment.IREMBOPAY_PAYMENT_DATA.invoiceNumber,
            currency: Payment.IREMBOPAY_PAYMENT_DATA.currency,
            merchant: Payment.IREMBOPAY_PAYMENT_DATA?.appAccount?.name,
            bank: payload.bank,
            paymentMethod: "BANK TRANSFER"
          });
          return resolve(res['success']);
        })
        .catch((err) => {
          this.trackEvent('Initiate Invoice Payment Error', {
            invoiceNumber: Payment.IREMBOPAY_PAYMENT_DATA.invoiceNumber,
            currency: Payment.IREMBOPAY_PAYMENT_DATA.currency,
            merchant: Payment.IREMBOPAY_PAYMENT_DATA?.appAccount?.name,
            paymentMethod: "BANK TRANSFER",
            errorMessage: err
          });
          return reject(err);
        });
    });
  }

  static updateBankTransfer(payload) {
    var data = {
      invoiceNumber: Payment.IREMBOPAY_PAYMENT_DATA.invoiceNumber,
      paymentProvider: payload.provider,
      swiftCode: payload.swiftCode,
      accountNumber: payload.accountNumber,
      accountName: payload.accountName
    };
    var url = `${Actions.getApiBaseUrl()}/payments/transactions/bank-transfer`;
    return new Promise((resolve, reject) => {
      HttpRequest.put(url, data)
        .then((res) => {
          this.trackEvent('Update Bank transfer Payment', {
            invoiceNumber: Payment.IREMBOPAY_PAYMENT_DATA.invoiceNumber,
            currency: Payment.IREMBOPAY_PAYMENT_DATA.currency,
            merchant: Payment.IREMBOPAY_PAYMENT_DATA?.appAccount?.name,
            bank: payload.bank,
            paymentMethod: "BANK TRANSFER"
          });
          return resolve(res['success']);
        })
        .catch((err) => {
          this.trackEvent('Update Bank transfer Payment Error', {
            invoiceNumber: Payment.IREMBOPAY_PAYMENT_DATA.invoiceNumber,
            currency: Payment.IREMBOPAY_PAYMENT_DATA.currency,
            merchant: Payment.IREMBOPAY_PAYMENT_DATA?.appAccount?.name,
            language: Payment.IREMBOPAY_PAYMENT_DATA.language,
            paymentMethod: "BANK TRANSFER",
            errorMessage: err
          });
          return reject(err);
        });
    });
  }

  static initiate(data) {
    Actions.setEnvironment();
    Actions.initializeStyles();
    Actions.initializeFontAwesome();
    this.initMixPanel();
    if (!data) {
      this.trackEvent('Load Payment Page Error', {
        data: data,
        errorMessage: "Payload required to load payment page"
      });
      throw new Error("Payload required")
    }

    Payment.IREMBOPAY_PAYMENT_DATA = data;
    window._iremboPayClientCallback = data.callback;

    if (!data.callback) {
      this.trackEvent('Load Payment Page Error', {
        data: data,
        errorMessage: "Callback required"
      });
      throw new Error("Callback required")
    }

    if (!data.invoiceNumber) {
      this.trackEvent('Load Payment Page Error', {
        data: data,
        errorMessage: "Invoice required"
      });
      return data.callback({ code: IremboPayErrors.invoiceMissing, message: "Invoice required" }, null);
    }

    if (IremboPayErrors.isBadLocale(data.locale)) {
      this.trackEvent('Load Payment Page Error', {
        data: data,
        errorMessage: "Unsupported locale"
      });
      return data.callback({ code: IremboPayErrors.badLocale, message: "Unsupported locale" }, null);
    }

    HtmlBuilder.createPaymentModal({ loading: true });
    Actions.closeEventSource();
    this.verifyInvoice(data).then((res) => {
      this.IREMBOPAY_VERIFICATION_DATA = res;
      const { cardPaymentProfile } = res;
      const { eventKey } = res;

      if (cardPaymentProfile === "MPGS") {
        this.configureMPGS(res);
      }else {
        Actions.registerStatusNotification({ ...data, eventKey});
      }

      HtmlBuilder.createPaymentModal(res);

      this.trackEvent('Load Payment Page', {
        invoiceNumber: res.invoiceNumber,
        currency: res.currency,
        merchant: res?.appAccount?.name,
        language: data.locale.toUpperCase()
      });
    }).catch(_error => {
      Actions.removeCustomAlert();

      try {
        var errorObj = IremboPayErrors.getError(_error)
        HtmlBuilder.createPaymentModal({ ..._error, error: { message: errorObj.details } });

        this.trackEvent('Load Payment Page Error', {
          invoiceNumber: data.invoiceNumber,
          language: data.locale.toUpperCase(),
          errorMessage: errorObj.details
        });

        return data.callback({ code: errorObj.code, message: errorObj.details }, null);
      } catch (_) { }
      HtmlBuilder.createPaymentModal({ ..._error, error: { message: "Invalid request" } });

      this.trackEvent('Load Payment Page Error', {
        invoiceNumber: data.invoiceNumber,
        language: data.locale.toUpperCase(),
         errorMessage: "Invalid request"
      });
      return data.callback({ code: IremboPayErrors.invalidRequest, message: "Invalid request" }, null);
    });
  }

  static reVerifyInvoice(retry, isMPGS) {
    this.trackEvent("Payment Failure",{
      invoiceNumber: this.IREMBOPAY_VERIFICATION_DATA.invoiceNumber,
      currency: this.IREMBOPAY_VERIFICATION_DATA.currency,
      merchant: this.IREMBOPAY_VERIFICATION_DATA?.appAccount?.name,
      paymentMethod: isMPGS ? "CARD MPGS": "CARD CYBERSOURCE"
    })
    if(isMPGS){
      this.reinitiateMPGS();
    }else {
      Actions.closeEventSource();
      this.verifyInvoice(Payment.IREMBOPAY_PAYMENT_DATA).then((res) => {
        this.IREMBOPAY_VERIFICATION_DATA = res;
        const {eventKey} = res;
        Actions.registerStatusNotification({...Payment.IREMBOPAY_PAYMENT_DATA, eventKey});
        Actions.onCardSubmit(Payment.IREMBOPAY_PAYMENT_DATA, this.IREMBOPAY_VERIFICATION_DATA, retry);
      }).catch(_error => {
        Payment.closeModal();
        try {
          var errorObj = IremboPayErrors.getError(_error)
          HtmlBuilder.createPaymentModal({..._error, error: {message: errorObj.details}});
          return data.callback({code: errorObj.code, message: errorObj.details}, null);
        } catch (_) {
        }
        HtmlBuilder.createPaymentModal({..._error, error: {message: "Invalid request"}});
        return data.callback({code: IremboPayErrors.invalidRequest, message: "Invalid request"}, null);
      });
    }
  }

  static closeModal() {
    Actions.removeCustomAlert()
  }

  static configureMPGS(res){
    const { eventKey } = res;
    const sessionId = res['mpgsProfileDto'].sessionId;
    this.configure3DS(res);
    this.configurePaymentSession(res);
    Actions.registerStatusNotification({ ...Payment.IREMBOPAY_PAYMENT_DATA, eventKey, sessionId });
  }

  static reinitiateMPGS(){
    HtmlBuilder.removeMPGSEventListeners();
    Payment.closeModal();
    this.initiate(Payment.IREMBOPAY_PAYMENT_DATA);
  }

  static initMixPanel(){
    this.getEnvironmentVariables()
      .then((res) => {
        mixpanel.init(res['mixPanelToken'], {
          debug: false,
          track_pageview: false,
          persistence: "localStorage",
        });
      })
  }

  static trackEvent(eventName, data){
    mixpanel.track(eventName, data);
  }

  static getEnvironmentVariables() {
    return new Promise((resolve, reject) => {
      var url = Actions.getApiBaseUrl()+"/environments/customer-ui";
      HttpRequest.get(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }
}


class IremboPay {
  static initiate(data) {
    Payment.initiate(data);
  }

  static closeModal() {
    Payment.closeModal();
  }

  static locale = {
    EN: 'en',
    RW: 'rw',
    FR: "fr"
  }
}
window.IremboPay = IremboPay;
